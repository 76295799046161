import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainRoute from "./MainRoute";
import DashboardPage from "../Pages/Dashboard/Index";
import Users from "../Pages/Users/Users";
import Fixers from "../Pages/Users/Fixers";
import Messages from "../Pages/Messages/Messages";
import AdminProfilePage from "../Pages/Profile/Profile";
import LoginIndex from "../Pages/Login/LoginIndex";
import FixerConversation from "../Pages/FixerMessages/FixerConversation";
import ResetPwd from "../ResetPassword/Reset";
import AllJobs from "../Pages/AllJobs/AllJobs";

const index = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`${process.env.PUBLIC_URL}/resetPassword/:id`}
          element={<ResetPwd />}
        />
        <Route path={`${process.env.PUBLIC_URL}/`} element={<LoginIndex />} />
        <Route element={<MainRoute />}>
          <Route
            path={`${process.env.PUBLIC_URL}/Dashboard`}
            element={<DashboardPage />}
          />
          <Route path={`${process.env.PUBLIC_URL}/users`} element={<Users />} />
          <Route path={`${process.env.PUBLIC_URL}/setting`} element={"about"} />
          <Route
            path={`${process.env.PUBLIC_URL}/fixers`}
            element={<Fixers />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/messages`}
            element={<Messages />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/profile`}
            element={<AdminProfilePage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/fixerConversation/:user_name/:id`}
            element={<FixerConversation />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/alljobs`}
            element={<AllJobs />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default index;
