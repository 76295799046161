import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid, IconButton, Rating, Tooltip } from "@mui/material";
import imageprofile from ".././Assets/Images/two.png";
import MaleAvtarProfile from ".././Assets/Images/four.png";

import { Link } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import moment from "moment";
import placeHolderImage from "../Assets/Images/placeholder.svg";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

import StarOutlineIcon from "@mui/icons-material/StarOutline";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "#f1f3f5",
  //   border: "2px solid #000",
  borderRadius: "5px",
  boxShadow: 24,
  p: 1,
};

export default function JobProviderModal({ open, setOpen, user }) {
  const [chatUser, setChatUsers] = React.useState([]);

  const test = async () => {
    const ref = collection(db, "Chats");

    // const questionsRef = collection(chatref, "messages");
    const questionsSnapshot = await getDocs(ref);
    const data = questionsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setChatUsers(data);
  };

  const chatWithUser = chatUser?.filter((item) => {
    let { members, memberIds, id } = item;
    return item.memberIds?.includes(user?._id);
  });

  const filteredData = chatUser.map((obj) => {
    return obj.member;
  });

  const filteredArray = chatUser?.filter((item) => {
    item.member = item.members.filter((member) => member._id !== user?._id);

    return item?.memberIds?.some((obj) => obj === user?._id);
  });

  const uniqueMembers = new Set();
  const filteredConversations = filteredArray?.reduce(
    (filtered, conversation) => {
      const memberName = conversation.member[0].name; // Assuming there's always at least one member in each conversation

      // Check if the member name already exists in the Set
      if (!uniqueMembers.has(memberName)) {
        // Add the member name to the Set
        uniqueMembers.add(memberName);

        // Add the conversation to the filtered array
        filtered.push(conversation);
      }

      return filtered;
    },
    []
  );

  React.useEffect(() => {
    test();
  }, []);
  const handleClose = () => setOpen(false);

  let Image;

  if (user?.profileImage === null) {
    Image = placeHolderImage;
  } else {
    Image = user?.profileImage;
  }

  let MainCategary = [];

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              position: "relative !important",
              display: "flex",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                right: "7px",
                top: "7px",
              }}
              onClick={() => handleClose()}
            >
              <CloseOutlinedIcon sx={{ color: "black", fontSize: "14px" }} />
            </IconButton>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={6} md={5}>
              <div
                className="user-info"
                style={{
                  background: "white",
                  padding: "60px 20px",
                  width: "100%",
                  height: "460px",
                }}
              >
                <div
                  className="profileImageSection"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="UserImage-profile">
                    <img src={Image} alt="" />
                    {user?.approved && (
                      <div className="user-status">
                        <Tooltip title="Approved" placement="top-start">
                          <IconButton>
                            <DoneIcon
                              sx={{ color: "white", fontSize: "14px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <strong style={{ fontSize: "24px", marginBottom: "5px" }}>
                    {user && user?.FullName}
                  </strong>

                  <span
                    className="main-role-user"
                    style={{
                      color: "#2B5238",
                      display: "flex",
                      justifyContent: "center",
                      lineHeight: "1",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <CallOutlinedIcon style={{ fontSize: "8px !important" }} />
                    {/* <span>
                      {user && user?.Address?.city_state?.split(",")?.[0]}
                    </span> */}

                    {
                      //  let MobileNumbber = `${countryCode} ${mobileNo}`;
                      console.log(user?.countryCode, user?.mobileNo, "user")
                    }
                    <span
                      style={{ margin: "0 5px" }}
                    >{`${user?.countryCode} ${user?.mobileNo}`}</span>
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <span style={{ fontSize: "12px", letterSpacing: "0.1" }}>
                    Rankings :
                  </span>
                  <div style={{ display: "flex", alignItems: "Center" }}>
                    <span
                      style={{
                        color: "#2B5238",
                        fontWeight: "bold",
                        margin: "0 6px",
                      }}
                    >
                      {user?.rating}
                    </span>
                    {user?.rating !== null ? (
                      <Rating
                        name="text-feedback"
                        value={user?.rating}
                        readOnly
                        color="primary"
                        precision={0.5}
                        emptyIcon={
                          <StarOutlineIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                    ) : (
                      <span style={{ fontSize: "11px", color: "gray" }}>
                        {user?.FullName} not submitted any ratings yet.
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={7}>
              <div
                className="basic-info"
                style={{
                  padding: "30px 20px",
                  width: "100%",
                  height: "460px",
                  background: "white",
                }}
              >
                <strong>Basic Information :</strong>
                <br />
                <ul>
                  <li style={{ width: "80px" }}>
                    Age
                    <span>
                      {user?.dob !== ""
                        ? `${moment().diff(user?.dob, "years")} years`
                        : "-:-"}
                    </span>
                  </li>

                  {/* <li>
                    Chargers{" "}
                    <span>{user?.charges ? "3k(per/hrs)" : " - : - "}</span>
                  </li> */}
                  <li style={{ width: "200px" }}>
                    Location{" "}
                    <span>
                      {" "}
                      {user?.Address?.city_state
                        ? user?.Address?.city_state
                        : "- : -"}
                    </span>
                  </li>
                </ul>
                <div className="job-description">
                  <strong>Job History :</strong>
                  <ul>
                    <li>
                      <WorkOutlineOutlinedIcon className="job-detial-icon" />{" "}
                      All Jobs{" "}
                      <span>{user?.AllJobs ? user?.AllJobs : "0"}</span>
                    </li>

                    <li>
                      <WorkHistoryOutlinedIcon className="job-detial-icon" />
                      In Process
                      <span>{user?.InProcessJob ? user?.InProcessJob : 0}</span>
                    </li>
                    <li>
                      <CheckCircleOutlineOutlinedIcon className="job-detial-icon" />
                      Completed{" "}
                      <span>
                        {user?.completedJobs ? user?.completedJobs : "0"}{" "}
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="user-chat">
                  <strong>
                    All chats :{" "}
                    <Link
                      to={`${
                        process.env.PUBLIC_URL
                      }/fixerConversation/${user?.FullName.trim("")}/${
                        user?._id
                      }`}
                    >
                      View All Chats
                    </Link>
                  </strong>

                  <div
                    className="allChatUserMesage"
                    style={{ display: "flex" }}
                  >
                    {filteredConversations?.length !== 0 ? (
                      filteredConversations?.map((item, index) => {
                        return item?.member?.slice(0, 8)?.map((user, index) => {
                          return (
                            <Tooltip key={index} title={`${user.name}`}>
                              <div className="userImages">
                                <img
                                  src={
                                    user.profileImage !== null
                                      ? user.profileImage
                                      : imageprofile
                                  }
                                  alt=""
                                />
                              </div>
                            </Tooltip>
                          );
                        });
                      })
                    ) : (
                      <Typography
                        variant="body2"
                        color="gray"
                        sx={{ pt: 3, mb: 2 }}
                      >
                        No Converstation
                      </Typography>
                    )}
                  </div>
                </div>

                <br />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
