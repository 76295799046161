import { createSlice } from "@reduxjs/toolkit";

const openSidebar = createSlice({
  name: "openSidebar",
  initialState: {
    open: false,
  },
  reducers: {
    updateOpen: (state, action) => {
      state.open = !state.open;
    },
  },
});

export const { updateOpen } = openSidebar.actions;
export default openSidebar.reducer;
