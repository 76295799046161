import {
  Box,
  Button,
  List,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { collection, getDocs } from "firebase/firestore";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import placeHolderImage from "../../Assets/Images/placeholder.svg";

const ConversationChat = ({ classes, LakhanImage, UsersMessages }) => {
  const { id } = useParams();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const lastMessageRef = useRef(null);

  const user = useSelector((state) => state.seeChat.chat);
  const chatUser = user?.member ? user?.member?.[0] : null;
  console.log("chatUser?.jobData?.jobId", user);
  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setMessages([
      ...messages,
      { id: messages.length + 1, text: message, sender: "user" },
    ]);
    setMessage("");
  };

  const getMessages = async () => {
    const ref = collection(db, "Chats");
    let questionsRef = [];
    if (!chatUser) {
      const tempChatUser = UsersMessages?.[0]?.member?.[0];
      questionsRef = collection(
        ref,
        (tempChatUser?._id > id
          ? `${id}-${tempChatUser?._id}`
          : `${tempChatUser?._id}-${id}`) +
          "-" +
          UsersMessages?.[0]?.jobData?.jobId,
        "messages"
      );
    } else {
      console.log("chatUser", chatUser);
      console.log(
        "query",
        (chatUser?._id > id
          ? `${id}-${chatUser?._id}`
          : `${chatUser?._id}-${id}`) +
          "-" +
          user?.jobData?.jobId
      );
      questionsRef = collection(
        ref,
        (chatUser?._id > id
          ? `${id}-${chatUser?._id}`
          : `${chatUser?._id}-${id}`) +
          "-" +
          user?.jobData?.jobId,
        "messages"
      );
    }
    const questionsSnapshot = await getDocs(questionsRef);
    const data = questionsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setMessages(data);
  };

  useEffect(() => {
    getMessages();
  }, [chatUser]);

  // chat user image setting
  let Image;
  if (chatUser) {
    if (chatUser?.profileImage === null) {
      Image = placeHolderImage;
    } else {
      Image = chatUser?.profileImage;
    }
  } else {
    if (UsersMessages[0]?.member?.[0]?.profileImage === null) {
      Image = placeHolderImage;
    } else {
      Image = UsersMessages[0]?.member?.[0]?.senderId?.profileImage;
    }
  }

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <Paper className={classes.users}>
      <div className="chat-bot-section">
        <img
          src={
            !chatUser
              ? UsersMessages?.[0]?.member?.[0]?.profileImage
              : chatUser?.profileImage
          }
          alt={chatUser?.name}
        />
        <div className="chat-bot-user-info">
          <div>
            {!chatUser ? UsersMessages?.[0]?.member?.[0].name : chatUser?.name}
          </div>
          <div className="chat-bot-user-email ">
            {!chatUser ? UsersMessages[0]?.email : chatUser?.email}
          </div>
        </div>
      </div>
      <Box className={classes.paper}>
        <div className="user-messages">
          <ul className="list-messaegs">
            {messages?.length !== 0 ? (
              messages?.map((message) => (
                <li primary={message.text}>
                  <div
                    style={
                      message.sentBy === id + ""
                        ? { display: "flex" }
                        : { display: "flex", flexDirection: "row-reverse" }
                    }
                    // align={message.sentBy === id + "" ? "left" : "right"}
                  >
                    <div
                      className="chat-profile"
                      style={
                        message.sentBy === id + "" ? {} : { marginLeft: "10px" }
                      }
                    >
                      {message.sentBy !== id + "" ? (
                        <img
                          src={
                            message?.user?.profileImage === null
                              ? placeHolderImage
                              : message?.user?.profileImage
                          }
                          alt=""
                        />
                      ) : (
                        <img
                          src={
                            message?.user?.profileImage === null
                              ? placeHolderImage
                              : message?.user?.profileImage
                          }
                          alt=""
                        />
                      )}
                    </div>
                    <div className="message-section-text">
                      <p
                        style={
                          message.sentBy === id + ""
                            ? {
                                my: 1,
                                width: "object-fit",
                                marginRight: "auto",
                                background: "#9a9aa0",
                                padding: "10px 30px",
                                borderRadius: "15px 15px  15px 0",
                                listStyle: "none",
                              }
                            : {
                                my: 1,
                                width: "object-fit",
                                marginLeft: "auto",
                                background: "#b3c1b7",
                                // width: 70 + "%",
                                padding: "10px 30px",

                                borderRadius: "15px 15px 0 15px",
                                listStyle: "none",
                              }
                        }
                      >
                        {message.text}
                      </p>
                      <span
                        style={
                          message.sentBy === id + ""
                            ? {
                                display: "flex",
                                fontSize: "12px",
                                color: "#c2c2c6",
                              }
                            : {
                                display: "flex",
                                fontSize: "12px",
                                color: "#c2c2c6",
                                justifyContent: "end",
                              }
                        }
                      >
                        {moment(message.createdAt).fromNow()}
                      </span>
                    </div>
                  </div>
                  {/* {message.text} */}
                </li>
              ))
            ) : (
              <li style={{ textAlign: "center" }}>No Messages Record</li>
            )}
            <div ref={lastMessageRef}></div>
          </ul>
        </div>
      </Box>
    </Paper>
  );
};

export default ConversationChat;
