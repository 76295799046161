import styled from "@emotion/styled";
import { Slide } from "@mui/material";
import React from "react";
import LogoImage from "../../Assets/Images/logo.png";
import ForgotScreen from "./ForgotScreen";
import LoginForm from "./LoginForm";

const MainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "85vh",
  padding: "10px",
  display: "flex",
  // justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
}));

const Login = ({ value, setValue, screen, setScreen }) => {
  return (
    <>
      {screen ? (
        <ForgotScreen setScreen={setScreen} />
      ) : (
        <MainDiv>
          <div className="logo-Image">
            <img src={LogoImage} alt="" />
          </div>
          <h3>Fixeo Login</h3>

          {/* tab */}
          <div style={{ margin: "30px 40px 0 25px" }}>
            <LoginForm screen={screen} setScreen={setScreen} />
          </div>
        </MainDiv>
      )}
    </>
  );
};

export default Login;
