import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CheckIcon from "@mui/icons-material/Check";
import { getApproveFixer } from "../../../Axios/ApisCall";
import UserModal from "../../../UserModals/UserModal";
import JobProviderModal from "../../../UserModals/JobProviderModal";
export default function ActionMenu({
  pickUsers,
  onDelete,
  onDisapprove,
  onApprove,
  user,
  loading,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [seeUserDetail, setSeeuserDetil] = useState();
  const [openModal, setOpenMOdal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    let userObject = {
      userid: pickUsers._id,
    };
    onDelete(userObject);
    // setAnchorEl(null);
  };

  const handleDisapprove = () => {
    let userObject = {
      userid: pickUsers._id,
    };
    onDisapprove(userObject);
    setAnchorEl(null);
  };

  const handleOpen = (data) => {
    if (data.role === "User") {
      setIsModalOpen(true);
      setAnchorEl(null);
    } else {
      setOpenMOdal(true);
      setAnchorEl(null);
    }
    setSeeuserDetil(pickUsers);
  };

  const handlerApprove = () => {
    let data = {
      userid: pickUsers._id,
    };

    onApprove(data);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 22,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleOpen(user)}>
          <RemoveRedEyeIcon sx={{ fontSize: "20px" }} /> &nbsp; View
        </MenuItem>

        <MenuItem onClick={handleDelete}>
          <DeleteOutlineIcon sx={{ fontSize: "20px" }} /> &nbsp; Delete
        </MenuItem>

        {user && user?.approved === false && user.role === "Fixer" ? (
          <MenuItem onClick={handlerApprove}>
            <CheckIcon sx={{ fontSize: "20px" }} /> &nbsp; Approve
          </MenuItem>
        ) : user && user.role === "User" ? (
          ""
        ) : (
          <MenuItem onClick={handleDisapprove}>
            <NotInterestedIcon size="small" sx={{ fontSize: "20px" }} />{" "}
            &nbsp;&nbsp;Disapprove
          </MenuItem>
        )}
      </Menu>
      <UserModal open={openModal} setOpen={setOpenMOdal} user={seeUserDetail} />
      <JobProviderModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        user={seeUserDetail}
      />
    </div>
  );
}
