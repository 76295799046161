import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardCard from "../../Component/DashboardCard";
import WorkIcon from "@mui/icons-material/Work";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PeopleIcon from "@mui/icons-material/People";
import { Stack } from "@mui/system";
import DashboardTable from "../../Component/DashboardTable";
import { getAllDashboardCount } from "../../Axios/ApisCall";
import EarningCard from "../../Component/EarningCard";
import RecentPostedusers from "../../Component/RecentPostedUsers";
import AllJobsCountGraph from "./AllJobsCountGraph";

const Series = [];
const options = {
  chart: {
    type: "donut",
    width: 400,
    toolbar: {
      show: false,
    },
  },
  fill: {
    colors: ["#62CA55", "#FABA3E"],
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: undefined,
    formatter: function (val, opts) {
      return val.toFixed(1) + "%";
    },
    style: {
      fontSize: "11px",
      fontWeight: "bold",
      colors: ["White", "white"],
    },
  },
  tooltip: {
    enabled: true,
    enabledOnSeries: false,
    theme: false,
    fillSeriesColor: false,
    style: {
      background: "red",
      title: {
        background: ["red", "yellow"],
      },
      hover: {
        background: ["blue", "red"], // Customize tooltip hover background color
      },
    },
    y: {
      formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
        return value;
      },
      title: {
        formatter: (seriesName) => {
          var title;
          if (seriesName === "series-1") {
            title = "Completed Jobs";
          } else if (seriesName === "series-2") {
            title = "In Process Jobs";
          }
          return title;
        },
      },
    },
    onItemHover: {
      highlightDataSeries: false,
    },
  },
  legend: {
    customLegendItems: ["Completed Jobs", "In Process Job"],
    position: "bottom",
    formatter: function (seriesName, opts) {
      return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]];
    },
    onItemHover: {
      highlightDataSeries: true,
    },
    fontSize: "16px",
    fontWeight: 600,
    horizontalAlign: "center",
    labels: {
      colors: ["#62CA55", "#FABA3E"],
    },
    markers: {
      width: 12,
      height: 12,
      fillColors: ["#62CA55", "#FABA3E"],
      radius: 12,
    },
  },
};
const Index = () => {
  const [dashboardState, setDashboardState] = useState([]);
  const [state, setState] = useState({ Series, options });
  const [refreshCount, setRefreshCount] = useState(false);
  useEffect(() => {
    getAllDashboardCount().then((res) => {
      setDashboardState(res);
      setState({
        ...state,
        Series: [res?.completedJobs, res?.inProgressJobs],
      });
    });
    setRefreshCount(false);
  }, [refreshCount]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item spacing={2} xs={8}>
          <Stack direction="row" spacing={2}>
            <Grid item spacing={2} xs={6}>
              <Stack direction="column" spacing={2}>
                <Grid item xs={12}>
                  <DashboardCard
                    title="Total users"
                    count={dashboardState && dashboardState?.totalUsers}
                    CardIcon={
                      <PeopleIcon
                        sx={{ fontSize: 2.5 + "rem", color: "#61CA54" }}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <DashboardCard
                    title="Fixer"
                    count={dashboardState && dashboardState?.totalFixers}
                    CardIcon={
                      <EngineeringIcon
                        sx={{ fontSize: 2.5 + "rem", color: "#FABA3E" }}
                      />
                    }
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <AllJobsCountGraph state={state} />
            </Grid>
          </Stack>

          <Grid xs={12} sx={{ mt: 2 }}>
            <DashboardTable
              Awiatingfixers={dashboardState?.recentProviders}
              setRefreshCount={setRefreshCount}
            />
          </Grid>
        </Grid>
        <Grid item xs={4} spacing={3} sx={{ height: "550px" }}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <EarningCard />
          </Grid>
          <Grid item xs={12}>
            <RecentPostedusers AllJobs={dashboardState.allJobs} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Index;
