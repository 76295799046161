import instance from "./AxiosIndex";

// user

// login user

export const getLoginUser = async (body) => {
  try {
    const Response = await instance.post(`/admin/login`, body);

    if (Response.status === 200) {
      return Response.data;
    }
  } catch (error) {
    return error;
  }
};

// getProfile

export const getProfile = async () => {
  try {
    let res = await instance.get(`admin/getadminprofile`);
    return res.data;
  } catch (error) {
    return error;
  }
};
// update profile

export const getUpdateProfile = async (userData) => {
  try {
    let res = await instance.put(`/admin/updateprofile`, userData);
    return res.data;
  } catch (error) {
    console.log("error", error);
    return error;
  }
};
// loggout

export const getLogOutProfile = async (userData) => {
  try {
    let res = await instance.put(`/admin/logout`);
    console.log("user profile", res);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const sendForgotLink = async (paramsBody) => {
  // let { id, pwd } = paramsBody;
  try {
    let res = await instance.post(`/admin/forgotpassword`, paramsBody);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

// =======================================
//           week+month+year-data
// ========================================

// admin/getDataByYear?year=2023
// {{url}}/admin/getdatabymonth?year=2023&month=3
// {{url}}/admin/getdatabyweek?weekNum=03&month=3

export const getWeekData = async (data) => {
  const { startDay, endDay } = data;

  try {
    let res = await instance.get(`/admin/getdatabyweek`, {
      params: {
        startDate: `${startDay}`,
        endDate: `${endDay}`,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};
export const getMonthData = async (data) => {
  const { year, month } = data;
  try {
    let res = await instance.get(`admin/getdatabymonth`, {
      params: {
        year: `${year}`,
        month: `${month + 1}`,
      },
    });
    return res.data.data;
  } catch (error) {
    return error;
  }
};
export const getYearData = async (data) => {
  console.log(data, "data");
  const { year } = data;

  try {
    let res = await instance.get(`admin/getDataByYear`, {
      params: {
        year: `${year}`,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getAllDashboardCount = async () => {
  try {
    let Response = await instance.get(`admin/dashboard`);
    if (Response.status == "200") {
      return Response.data;
    }
  } catch (error) {}
};
// get All  axios request
export const getAllUserList = async () => {
  try {
    let Response = await instance.get(`/admin/getuser`);
    if (Response.status == "200") {
      return Response.data;
    }
  } catch (error) {}
};

// get All  axios request
export const getAllFixersList = async () => {
  try {
    let Response = await instance.get(`/admin/getfixer`);
    if (Response.status == "200") {
      return Response.data;
    }
  } catch (error) {}
};

// get Single
const signleUser = async (paramsId) => {
  try {
    let Response = await instance.delete(`/groups/${paramsId}`);
    if (Response.status == "200") {
      return Response.data;
    }
  } catch (error) {}
};
// post axios request
export const resetPassword = async (paramsBody) => {
  let { id, pwd } = paramsBody;
  try {
    let res = await instance.post(`users/reset-password/${id}`, pwd);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error);
  }
};
// delete axios request
export const deleteGroup = async (paramsId) => {
  try {
    let Response = await instance.delete(`/groups/delete/${paramsId}`);
    if (Response.status == "200") {
      return Response.data;
    }
  } catch (error) {
    console.log("error", error);
  }
};
//  APPROVE USER (PUT REQUEST)
export const getApproveFixer = async (params) => {
  try {
    let res = await instance.put(`/admin/approve`, params);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error);
  }
};

//  REJECT USER (PUT REQUEST)
export const getRejectFixer = async (params) => {
  try {
    let res = await instance.put(`/admin/reject`, params);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error);
  }
};

//  DELETE USER (PUT REQUEST)
export const getDeleteUser = async (params) => {
  try {
    let res = await instance.put(`/admin/deletefixer`, params);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error);
  }
};

//  DISAPPROVE USER (PUT REQUEST)
export const getDeleteProvider = async (params) => {
  try {
    let res = await instance.put(`/admin/deleteuser`, params);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getQueryuserList = async () => {
  try {
    let Response = await instance.get(`admin/getUsersQuestion`);
    if (Response.status == "200") {
      console.log(Response.data);
      return Response.data;
    }
  } catch (error) {
    return error;
  }
};

export const getreplayMesage = async (body) => {
  console.log(body, "body");
  try {
    let res = await instance.post("admin/adminReplyOnQuestion", body);
    return res;
  } catch (error) {
    return error;
  }
};

export const getConversationById = async (id) => {
  try {
    let res = await instance.get(`admin/getCoverstationById?userId=${id}`);
    console.log("res=======", res);
    return res.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
