import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MuiDrawer from "@mui/material/Drawer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import { Collapse, List } from "@mui/material";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { useState } from "react";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideNav() {
  const [openSubTask, setOpenSubTask] = useState(false);
  const theme = useTheme();

  const UsePath = useLocation();
  const data = useSelector((state) => state.Open.open);

  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Drawer variant="permanent" open={data}>
        <DrawerHeader>
          <IconButton>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem
            key={"Dashboard"}
            disablePadding
            onClick={() => navigate(`${process.env.PUBLIC_URL}/Dashboard`)}
            sx={
              UsePath.pathname === "/Dashboard"
                ? { background: "#557560", color: "white" }
                : {}
            }
          >
            <ListItemButton>
              <ListItemIcon>
                <DashboardOutlinedIcon
                  style={
                    UsePath.pathname === "/Dashboard"
                      ? { color: "white" }
                      : {}
                  }
                />
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItemButton>
          </ListItem>

          <ListItem
            key={"Users"}
            disablePadding
            onClick={() => setOpenSubTask(!openSubTask)}
          >
            <ListItemButton>
              <ListItemIcon>
                <PeopleAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Users"} />
            </ListItemButton>{" "}
            &nbsp;
            <div className="upDownArrow">
              {openSubTask ? (
                <KeyboardArrowUpIcon className="upArrow" />
              ) : (
                <KeyboardArrowDownIcon className="downArrow" />
              )}
            </div>
          </ListItem>

          {/* ======================collpase menu ============ */}

          <Collapse in={openSubTask}>
            <List sx={{ pl: 1 }}>
              <ListItem
                key={"Users "}
                disablePadding
                label="users"
                onClick={() => navigate(`${process.env.PUBLIC_URL}/users`)}
                sx={
                  UsePath.pathname === "/users"
                    ? { background: "#557560", color: "white" }
                    : {}
                }
              >
                <ListItemButton>
                  <ListItemIcon>
                    <PeopleAltOutlinedIcon
                      style={
                        UsePath.pathname === "/users"
                          ? { color: "white" }
                          : {}
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Users"} />
                </ListItemButton>
              </ListItem>
              <ListItem
                key={"Fixers"}
                disablePadding
                onClick={() => navigate(`${process.env.PUBLIC_URL}/fixers`)}
                sx={
                  UsePath.pathname === "/fixers"
                    ? { background: "#557560", color: "white" }
                    : {}
                }
              >
                <ListItemButton>
                  <ListItemIcon>
                    <EngineeringIcon
                      style={
                        UsePath.pathname === "/fixers"
                          ? { color: "white" }
                          : {}
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Fixers"} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>

          {/* <ListItem
            key={"Setting"}
            disablePadding
            onClick={() => navigate(`${process.env.PUBLIC_URL}/setting`)}
            sx={
              UsePath.pathname === "/setting"
                ? { background: "#557560", color: "white" }
                : {}
            }
          >
            <ListItemButton>
              <ListItemIcon>
                <SettingsOutlinedIcon
                  style={
                    UsePath.pathname === "/setting" ? { color: "white" } : {}
                  }
                />
              </ListItemIcon>
              <ListItemText primary={"Setting"} />
            </ListItemButton>
          </ListItem> */}

          <ListItem
            key={"Get Help"}
            disablePadding
            onClick={() => navigate(`${process.env.PUBLIC_URL}/Messages`)}
            sx={
              UsePath.pathname === "/Messages"
                ? { background: "#557560", color: "white" }
                : {}
            }
          >
            <ListItemButton>
              <ListItemIcon>
                <ChatOutlinedIcon
                  style={
                    UsePath.pathname === "/Messages"
                      ? { color: "white" }
                      : {}
                  }
                />
              </ListItemIcon>
              <ListItemText primary={"Get Help"} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {/* here all children route come */}
        <Outlet />
      </Box>
    </Box>
  );
}
