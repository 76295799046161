import {
  Box,
  Button,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import WorkIcon from "@mui/icons-material/Work";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const users = [
  {
    id: 1,
    name: "lakhan",
    lastPostedAt: "3pm",
    pictureUrl: "",
    lastupdated: "3 Aug",
    jobPost: "plumbing work",
  },
  { id: 1, name: "shubam", lastPostedAt: "3pm" },
];

const RecentPostedusers = ({ AllJobs }) => {
  const navigate = useNavigate();
  return (
    <Card sx={{ px: 2, pt: 0 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // marginBottom: "30px",
        }}
      >
        <h5 style={{ fontSize: "16px" }}>Latest Job's</h5>
        <Button
          variant="outlined"
          size="small"
          onClick={() => navigate(`${process.env.PUBLIC_URL}/AllJobs`)}
        >
          See All
        </Button>
      </div>
      {users?.length !== 0 ? (
        <List sx={{ pt: 0 }}>
          {AllJobs?.slice(0, 2)?.map((user) => {
            return (
              <ListItem key={user.id} disablePadding sx={{ mb: 1 }}>
                <div className="list-user-list-item">
                  <div className="icon-and-info">
                    <div className="jobPostIcons">
                      <WorkIcon className="workIconClass" />
                    </div>

                    <Box className="user-name-message" sx={{ ml: 1 }}>
                      <ListItemText
                        id={user.id}
                        primary={`${user.jobData?.category?.Maincategory}`}
                      />
                      <div
                        className="chat-bot-user-email"
                        style={{ lineHeight: "0.7" }}
                      >
                        {moment(user?.jobData?.createdAt).fromNow()}
                      </div>
                    </Box>
                  </div>
                </div>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Typography color="gray " sx={{ textAlign: "center", mb: 5 }}>
          No Records Yet...
        </Typography>
      )}
    </Card>
  );
};

export default RecentPostedusers;
