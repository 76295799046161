import { Alert, Button, ListItem, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import LogoImage from "../../Assets/Images/logo.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { sendForgotLink } from "../../Axios/ApisCall";
import { useState } from "react";

const ForgotScreen = ({ setScreen }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showComponent, setsetShowWarning] = useState(false);
  const [showError, setShowError] = useState(false);

  const [state, setState] = useState({ AlertType: "" });
  const getValues = (values, resetForm) => {
    if (values.email.length === 0) {
      setsetShowWarning(true);
      setTimeout(() => {
        setsetShowWarning(false);
      }, 2000);
    } else if (values.email.length !== 0) {
      sendForgotLink(values).then((res) => {
        if (res.status !== 1) {
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 2000);
        } else {
          setShowSuccess(true);
          setTimeout(() => {
            setShowSuccess(false);
          }, 2000);
        }
      });
    }
  };

  return (
    <div className="forgot-Page">
      <div className="forgot-content">
        <div className="backButton">
          <ArrowBackIcon
            className="backarrow"
            onClick={() => setScreen(false)}
          />
        </div>
        <div className="logo-Image Image-Section">
          <img src={LogoImage} alt="" />
        </div>
        <p>
          please enter your email address we will send you an email to reset
          your password.
        </p>
        <div>
          <Formik
            initialValues={{ email: "" }}
            onSubmit={(values, { resetForm }) => {
              getValues(values, resetForm());
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isValid,
              dirty,
            }) => (
              <Form>
                <TextField
                  label="Email"
                  name="email"
                  size="small"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  fullWidth
                  sx={{ marginTop: "20px", marginBottom: "20px" }}
                />
                {showComponent && (
                  <Alert
                    severity="warning"
                    iconMapping={{
                      success: <CheckCircleOutlineIcon fontSize="inherit" />,
                    }}
                    sx={{ my: 1 }}
                  >
                    Please fill in all required fields
                  </Alert>
                )}

                {showError && (
                  <Alert
                    severity="error"
                    iconMapping={{
                      success: <CheckCircleOutlineIcon fontSize="inherit" />,
                    }}
                    sx={{ my: 1 }}
                  >
                    Invalid email address. Please try again.
                  </Alert>
                )}
                {showSuccess && (
                  <Alert
                    iconMapping={{
                      success: <CheckCircleOutlineIcon fontSize="inherit" />,
                    }}
                    sx={{ my: 1 }}
                  >
                    Success! Your action has been completed.
                  </Alert>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Send email
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotScreen;
