import { Form, Formik } from "formik";
import React, { useState, useCallback } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import { Alert, Button, Checkbox, TextField } from "@mui/material";

const ResetPwd = () => {
  // show password
  const [seePassword, setSeePassword] = useState(false);
  const [notMatch, setNotMatch] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emptyField, setEmptyField] = useState(false);

  const userId = useParams();
  const navigate = useNavigate();
  const getValues = (values, resetForm) => {
    if (!values.password && !values.confirmPwd) {
      setEmptyField(true);
      setTimeout(() => {
        setEmptyField(false);
      }, [2000]);
    }
    if (values.confirmPwd === values.password) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, [2000]);
      let New = {
        id: parseInt(userId.id),
        pwd: { password: values.confirmPwd },
      };
      resetForm();
    } else if (values.confirmPwd !== values.password) {
      setNotMatch(true);
      setTimeout(() => {
        setNotMatch(false);
      }, [2000]);
    }
  };

  const navigatePage = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleClikHandler = (e) => {
    setSeePassword(e.target.checked);
  };

  return (
    <div className="reset_section_page ">
      <div className="list-section">
        <div className="user-creation-content">
          <div className="logo-section">
            <KeyOutlinedIcon style={{ fontSize: "50px" }} />
          </div>
          <h2 className="Heading-forgot">Set new password?</h2>
          <p className="sub_title-forgot">
            your new password must be diffrent to previously used passwords.
          </p>
          <div
            className="alert_Show"
            style={{ width: "100%", margin: "2px 0" }}
          >
            {emptyField && (
              <Alert severity="warning">
                Please fill in all required fields.
              </Alert>
            )}
            {success && <Alert> successfully! Reset password.</Alert>}
            {notMatch && (
              <Alert severity="warning">Passwords do not match</Alert>
            )}
          </div>
          <Formik
            initialValues={{ password: "", confirmPwd: "" }}
            validate={(values) => {
              const errors = {};
              //   if (!values.password) {
              //     errors.password = "Required";
              //   }
              //   if (values.confirmPwd !== values.password) {
              //     errors.confirmPwd = "password does not match";
              //   } else if (!values.confirmPwd) {
              //     errors.confirmPwd = "";
              //   }

              return errors;
            }}
            onSubmit={(values, { resetForm }) => {
              getValues(values, resetForm);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isValid,
              dirty,
            }) => (
              <Form className="forgot-password-farm" style={{ width: "100%" }}>
                <div className="py-3  w-100 align-items-center ">
                  <label
                    htmlFor="gruop"
                    className="d-flex mb-2 align-items-center justify-space-between"
                    style={{ fontSize: "16px", marginBottom: "5px" }}
                  >
                    Password :
                  </label>
                  <div className="d-flex resetInput mb-2">
                    <TextField
                      //   label="Password"
                      name="password"
                      size="small"
                      type={seePassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password && Boolean(errors.email)}
                      helperText={touched.password && errors.email}
                      fullWidth
                      sx={{ marginTop: "8px", marginBottom: "20px" }}
                    />
                  </div>

                  <label
                    htmlFor="gruop"
                    className="d-flex mb-2 align-items-center justify-space-between"
                    style={{ fontSize: "16px", marginBottom: "5px" }}
                  >
                    Confirm password :
                  </label>
                  <div className="d-flex resetInput">
                    <TextField
                      //   label="Confirm Password"
                      name="confirmPwd"
                      size="small"
                      type={seePassword ? "text" : "password"}
                      value={values.confirmPwd}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.confirmPwd && Boolean(errors.email)}
                      helperText={touched.confirmPwd && errors.email}
                      fullWidth
                      sx={{ marginTop: "8px", marginBottom: "20px" }}
                    />
                  </div>
                </div>

                <label htmlFor="" className="d-flex">
                  <Checkbox
                    sx={{ px: 0, mr: 1 }}
                    size="small"
                    onChange={handleClikHandler}
                    checked={seePassword}
                  />
                  Show password
                </label>
                <div className=" text-center pt-3 pb-1">
                  <Button
                    type="Submit"
                    variant="contained"
                    className="  form-control"
                    fullWidth
                    sx={{ my: 2 }}
                  >
                    Reset password
                  </Button>

                  <Button
                    type="button"
                    className="  mt-2 form-control"
                    onClick={() => navigatePage()}
                    fullWidth
                    sx={{ my: 2 }}
                  >
                    <BsArrowLeftShort className="me-3" />
                    Back to LogIn
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPwd;
