import React from "react";
import Header from "../Layout/Header";
import SideNav from "../Layout/SideNav";

const MainRoute = () => {
  return (
    <div>
      <Header />
      <SideNav />
    </div>  
  );
};

export default MainRoute;
