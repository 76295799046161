import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { ErrorMessage, useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthLogin } from "../../Store/AuthSlice";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useState } from "react";
import { getLoginUser } from "../../Axios/ApisCall";

const LoginForm = ({ screen, setScreen }) => {
  const [visiblePwd, setVisiblePwd] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validate: (values) => {
      const errors = {};

      if (!values.email) {
        errors.email = "Email is required";
      } else if (values.email !== "adimulamshivani@gmail.com") {
        errors.email = "Invalid email address";
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = "Invalid email address";
      }

      if (!values.password) {
        errors.password = "Password is required";
      } else if (values.password !== "Shivani@123") {
        errors.password = "Invalid Password";
      } else if (values.password.length < 6) {
        errors.password = "Password must be  6 characters long";
      }
      return errors;
    },

    onSubmit: (values) => {
      getLoginUser(values).then((res) => {
        if (res) {
          localStorage.setItem("user", JSON.stringify(res.data.update));
          localStorage.setItem("user_token", res.data.token);
          navigate(`${process.env.PUBLIC_URL}/Dashboard`);
        }
      });
      // dispatch(AuthLogin(values)).then((res) => {
      //   console.log(res, "respons");
      //   // if (res.payload) {
      //   //
      //   // } else {
      //   //   navigate(`${process.env.PUBLIC_URL}/`);
      //   // }
      // });
    },
  });
  return (
    <div style={{ margin: "10px important" }}>
      <form onSubmit={formik.handleSubmit} style={{ position: "relative" }}>
        <TextField
          fullWidth
          id="email"
          name="email"
          // label="Email"
          placeholder="Email"
          size="small"
          autoComplete="off"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{ marginTop: "30px" }}
        />

        <FormControl
          sx={{
            marginTop: "30px",
          }}
          variant="outlined"
          fullWidth
          error={formik.touched.password && Boolean(formik.errors.password)}
        >
          {/* <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel> */}
          <OutlinedInput
            size="small"
            id="outlined-adornment-password"
            type={visiblePwd ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setVisiblePwd(!visiblePwd)}
                  edge="end"
                >
                  {visiblePwd ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
            name="password"
            placeholder="Password"
            autoComplete="off"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            // error={formik.touched.password && Boolean(formik.errors.password)}
            // helperText={formik.touched.password && formik.errors.password}
          />
        </FormControl>
        {formik.touched.password && formik.errors.password ? (
          <FormHelperText sx={{ color: "#d32f2f", marginLeft: "14px" }}>
            {formik.errors.password}
          </FormHelperText>
        ) : null}
        {/* <TextField
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="text"
          size="small"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          sx={{ marginTop: "30px", marginBottom: "25px", position: "relative" }}
        /> */}

        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          sx={{ marginTop: "30px" }}
        >
          Login
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;
