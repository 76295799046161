import {
  Alert,
  Avatar,
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Formik, Form, Field } from "formik";

import lakhanImage from "../../Assets/Images/one.png";
import { useEffect } from "react";
import { getProfile, getUpdateProfile } from "../../Axios/ApisCall";
import { useState } from "react";
import { TramOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  // wrapper: {
  //   background: "white",
  //   width: "100%",
  //   borderRadius: "82px 0 0 0 ",
  //   overflow: "hidden",
  // },

  button: {
    margin: theme.spacing(1),
  },
}));
const userProfile = JSON.parse(localStorage.getItem("user"));
function AdminProfilePage() {
  const classes = useStyles();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [Adminprofile, setAdminProfile] = useState(userProfile);
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    getProfile().then((res) => setAdminProfile(res.data));
  }, []);

  const getFormValues = (values) => {
    getUpdateProfile(values).then((res) => {
      if (res?.data) {
        setSuccess(TramOutlined);
        setAdminProfile(res.data);
        localStorage.setItem("user", JSON.stringify(res.data));
      }
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
  };

  return (
    <div style={{ width: "1000px", margin: "auto" }}>
      <Grid container spacing={1} className="profile-section">
        <div className="wrapper">
          <Grid
            item
            xs={12}
            sx={{
              background: "rgb(220 167 21 / 46%)",

              height: "150px",
              borderRadius: "82px 0 0 0 ",
            }}
          >
            <div></div>
          </Grid>
          <Grid item sx={{ position: "relative", left: "50px" }}>
            <Avatar
              // className={classes.avatar}
              alt="Admin Avatar"
              src={lakhanImage}
              sx={{
                position: "absolute",
                top: "-30px",
                width: "100px",
                height: "auto",
                border: "2px solid white",
              }}
            />
            <div style={{ position: "absolute ", left: "130px" }}>
              <Typography variant="h6" sx={{ width: 300 }}>
                {Adminprofile && Adminprofile.FullName}
              </Typography>
              <Typography variant="body2" color="grey">
                Update your photo and personal detail.
              </Typography>
            </div>
          </Grid>

          <Snackbar
            open={success}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Successfully updated profile.
            </Alert>
          </Snackbar>

          <Grid item>
            <Formik
              initialValues={{
                FullName: Adminprofile?.FullName,
                email: Adminprofile?.email,
                Address: Adminprofile?.Address,
              }}
              onSubmit={(values, actions) => {
                getFormValues(values);
              }}
            >
              {({ values }) => (
                <Form style={{ width: "900px", margin: "150px auto" }}>
                  <label
                    htmlFor=""
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "50px  0 0 0",
                    }}
                  >
                    <span style={{ width: "25%", textAlign: "center" }}>
                      Name &nbsp;
                    </span>

                    <Field name="FullName">
                      {({ field }) => (
                        <TextField
                          variant="outlined"
                          fullWidth
                          size="small"
                          {...field}
                        />
                      )}
                    </Field>
                  </label>
                  <label
                    htmlFor=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      margin: "50px 0 0  0",
                    }}
                  >
                    <span style={{ width: "25%" }}>Email &nbsp;</span>
                    <Field name="email">
                      {({ field }) => (
                        <TextField
                          variant="outlined"
                          fullWidth
                          disabled
                          size="small"
                          {...field}
                        />
                      )}
                    </Field>
                  </label>
                  <label
                    htmlFor=""
                    style={{
                      display: "flex",
                      textAlign: "center",
                      margin: "50px 0 0 0",
                    }}
                  >
                    <span style={{ width: "25%" }}>Adress &nbsp;</span>
                    <Field name="Address">
                      {({ field }) => (
                        <TextField
                          label="Address"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={4}
                          size="small"
                          {...field}
                        />
                      )}
                    </Field>
                  </label>

                  <div style={{ marginLeft: "205px", marginTop: "50px" }}>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}

export default AdminProfilePage;
