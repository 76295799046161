import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { CustomToolbar } from "./CustomeTable/CustomToolbar";
import { initialRows } from "./ColumnsAndRows/usersColumns";

import { useEffect, useState } from "react";
import { getAllUserList, getDeleteProvider } from "../../Axios/ApisCall";
import { CustomCellRenderer } from "./CustomeTable/CustomCellRenderer";
import ActionMenu from "./CustomeTable/ActionMenu";
import SearchIcon from "@mui/icons-material/Search";
import placeHolderImage from "../../Assets/Images/placeholder.svg";

import moment from "moment";
import CustomNoRowsOverlay from "./NoRow";
import { Rating } from "@mui/material";

export default function Users() {
  const [rows, setRows] = useState(initialRows);
  const [userData, setUserData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [renderList, setRenderList] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRows, setFilteredRows] = useState(userData);

  // search functionality
  const handleSearchChange = (event) => {
    // setSearchTerm(event.target.value);
    const value = event.target.value;
    setSearchTerm(value);

    const regex = new RegExp(event.target.value, "i");

    const filtered = userData.filter((row) => regex.test(row.FullName));

    setFilteredRows(filtered);
  };

  const [sendUserMenu, setSendUserMenu] = useState();

  const handleRowClick = (params) => {
    setSendUserMenu(params.row);
  };

  useEffect(() => {
    getAllUserList().then((res) => {
      setUserData(res.data);
    });
  }, [renderList]);

  const handleDelete = (data) => {
    getDeleteProvider(data).then((res) => {
      setRenderList(true);
    });
  };

  const columns1 = [
    {
      field: "FullName",
      headerName: "Name",
      flex: 2,

      sortable: false,
      renderCell: (params) => {
        const { FullName, email, gender, _id, profileImage } = params.row;
        return (
          <CustomCellRenderer
            name={FullName}
            imageSrc={profileImage === null ? placeHolderImage : profileImage}
            email={email}
          />
        );
      },
    },
    {
      field: "addressId",
      headerName: "Address",
      flex: 2,
      sortable: false,
      valueGetter: (params) => {
        if (params.row.Address) {
          return params.row.Address?.city_state;
        }
      },
    },
    {
      field: "mobileNo",
      headerName: "Mobile Number",
      flex: 2,
      sortable: false,
      valueGetter: (params) => {
        let { countryCode, mobileNo } = params.row;
        let MobileNumbber = `${countryCode} ${mobileNo}`;
        return MobileNumbber;
      },
    },
    {
      field: "age",
      headerName: "Age",
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let { dob } = params.row;
        console.log(dob);
        let age = moment().diff(dob, "years");
        return age || "";
      },
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        const { rating } = params.row;

        return rating ? (
          <Rating
            name="half-rating"
            readOnly
            defaultValue={rating}
            precision={0.5}
          />
        ) : null;
      },
    },
    {
      field: "createdAt",
      sortable: false,
      headerName: " Join Date",
      flex: 1,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY "),
    },

    {
      field: "action",
      headerName: "Action",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <>
          {params.value}
          <ActionMenu
            pickUsers={params.row}
            onDelete={handleDelete}
            user={sendUserMenu && sendUserMenu}
          />
        </>
      ),
    },
  ];

  function getRowId(row) {
    return row._id;
  }

  return (
    <div style={{ height: "80vh", width: "100%", position: "relative" }}>
      <div className="searchbar-mui-data-table">
        <SearchIcon color="primary" />

        <input
          type="text"
          name="serchTerm"
          value={searchTerm}
          onChange={(e) => handleSearchChange(e)}
        />
      </div>
      <DataGrid
        sx={{ background: "white" }}
        rows={searchTerm?.length !== 0 ? filteredRows : userData}
        columns={columns1}
        getRowId={getRowId}
        disableColumnMenu
        disableColumnSelector
        pageSize={10}
        slots={{
          NoResultsOverlay: CustomNoRowsOverlay,
        }}
        rowsPerPageOptions={[10]}
        onRowClick={handleRowClick}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
          Toolbar: () => (
            <div className="table-header">
              <h5>Users</h5>
              <GridToolbarContainer
                className="tableHeading-Content"
                sx={{ justifyContent: "flex-end" }}
              >
                <GridToolbarExport />
              </GridToolbarContainer>
            </div>
          ),
        }}
        disableSelectionOnClick
      />
    </div>
  );
}
