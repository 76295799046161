import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import ListItemIcon from "@mui/material/ListItemIcon";
import { Box } from "@mui/system";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { seeUserChat } from "../../Store/seeChatSlice";
import moment from "moment";

import placeHolderImage from "../../Assets/Images/placeholder.svg";

import { onConverstationChangeUser } from "./Messages";
import { getConversationById } from "../../Axios/ApisCall";

const ListOfChatUser = ({
  UsersMessages,
  firstUserMessage,
  classes,
  selectUserChat,
  userChat,
  setUser,
}) => {
  const dispatch = useDispatch();

  let selecteUserChat = useSelector((state) => state.seeChat.user);

  const checkingUserSelectedOrNot = (id) => {
    // TODO document why this arrow function is empty

    if (userChat && userChat?.userDetails?._id === id) {
      let styleObject = {
        background: "#40634c",
        color: "white",
        py: 1,
        px: 2,
      };
      return styleObject;
    } else {
      let styleObject = { background: "", py: 1, px: 2 };
      return styleObject;
    }
  };

  return (
    <Paper className={classes.users}>
      <List sx={{ mr: 1 }}>
        {UsersMessages &&
          UsersMessages?.map((user) => {
            console.log(user, "iddads");
            return (
              <ListItem
                key={user._id}
                sx={checkingUserSelectedOrNot(user?.userDetails?._id)}
                disablePadding
                onClick={() => {
                  dispatch(seeUserChat(user));
                  setUser(user);
                }}
                className="users-list-need-help"
              >
                <div className="list-user-list-item">
                  <div className="icon-and-info">
                    <ListItemIcon>
                      <img
                        src={
                          user?.userDetails?.profileImage !== null
                            ? user?.userDetails?.profileImage
                            : placeHolderImage
                        }
                        alt={Image}
                        className="list-user-image"
                      />
                    </ListItemIcon>

                    <Box className="user-name-message">
                      <ListItemText
                        id={user?.userDetails?._id}
                        primary={`${user?.userDetails?.FullName}`}
                      />
                      <div className="chat-bot-user-email">{user.message}</div>
                    </Box>
                  </div>
                  <div style={{ display: "flex" }}>
                    <p>{moment(user.createdAt).fromNow()}</p>
                  </div>
                </div>
              </ListItem>
            );
          })}
      </List>
    </Paper>
  );
};

export default ListOfChatUser;
