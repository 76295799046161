import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

import { useEffect, useState } from "react";
import {
  getAllDashboardCount,
  getAllUserList,
  getDeleteProvider,
} from "../../Axios/ApisCall";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import CustomNoRowsOverlay from "../../Pages/Users/NoRow";
import { Rating } from "@mui/material";

export default function AllJobs() {
  //   const [rows, setRows] = useState(initialRows);
  const [userData, setUserData] = useState([]);
  const [allJobsData, setAllJobsData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [renderList, setRenderList] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRows, setFilteredRows] = useState(userData);

  // search functionality
  const handleSearchChange = (event) => {
    // setSearchTerm(event.target.value);
    const value = event.target.value;
    setSearchTerm(value);

    const regex = new RegExp(event.target.value, "i");

    const filtered = allJobsData.filter((row) => {
      return (
        regex.test(row?.jobData?.category?.Maincategory) ||
        regex.test(row?.jobData?.jobProviderId?.FullName)
      );
    });
    setFilteredRows(filtered);
  };

  const [sendUserMenu, setSendUserMenu] = useState();

  const handleRowClick = (params) => {
    setSendUserMenu(params.row);
  };

  useEffect(() => {
    getAllDashboardCount().then((res) => {
      setAllJobsData(res.allJobs);
    });
  }, []);

  useEffect(() => {
    getAllUserList().then((res) => {
      setUserData(res.data);
    });
  }, [renderList]);

  const handleDelete = (data) => {
    getDeleteProvider(data).then((res) => {
      setRenderList(true);
    });
  };

  const columns1 = [
    // {
    //   field: "Sr.No",
    //   headerName: "Sr No",
    //   flex: 1,
    //   sortable: false,
    //   // valueGetter: (params) => `${params.row.index + 1}`,
    // },
    {
      field: "jobData.category",
      headerName: "Job Category",
      flex: 2,
      sortable: false,
      valueGetter: (params) => {
        return params.row?.jobData?.category?.Maincategory;
      },
    },
    {
      field: "jobData.jobProviderId",
      headerName: "Job Owner",
      flex: 1,
      sortable: false,
      valueGetter: (params) => {
        return params.row?.jobData?.jobProviderId?.FullName;
      },
    },
    {
      field: "fixerData.fixer",
      headerName: "Service Provider",
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row?.fixerData?.fixerId?.FullName || "";
      },
    },
    {
      field: "jobData",
      sortable: false,
      headerName: " Created On",
      flex: 1,
      valueFormatter: (params) => {
        console.log(params.value.createdAt, "params roww");
        return moment(params?.value?.createdAt).format("DD/MM/YYYY  hh:mm a");
      },
    },

    ,
    {
      field: "jobData.statusOfProgress",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      valueGetter: (params) => {
        return params.row?.jobData?.statusOfProgress;
      },
    },
  ];

  function getRowId(row) {
    return row?.jobData?._id;
  }

  return (
    <div style={{ height: "80vh", width: "100%", position: "relative" }}>
      <div className="searchbar-mui-data-table">
        <SearchIcon color="primary" />

        <input
          type="text"
          name="serchTerm"
          value={searchTerm}
          onChange={(e) => handleSearchChange(e)}
        />
      </div>
      <DataGrid
        sx={{ background: "white" }}
        rows={searchTerm?.length !== 0 ? filteredRows : allJobsData}
        columns={columns1}
        getRowId={getRowId}
        disableColumnMenu
        disableColumnSelector
        pageSize={10}
        slots={{
          NoResultsOverlay: CustomNoRowsOverlay,
        }}
        rowsPerPageOptions={[10]}
        onRowClick={handleRowClick}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
          Toolbar: () => (
            <div className="table-header">
              <h5>All Jobs</h5>
              <GridToolbarContainer
                className="tableHeading-Content"
                sx={{ justifyContent: "flex-end" }}
              >
                <GridToolbarExport />
              </GridToolbarContainer>
            </div>
          ),
        }}
        disableSelectionOnClick
      />
    </div>
  );
}
