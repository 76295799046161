import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import ListItemIcon from "@mui/material/ListItemIcon";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { seeOneToOneChat, seeUserChat } from "../../Store/seeChatSlice";
import moment from "moment";
import placeHolderImage from "../../Assets/Images/placeholder.svg";

const ConverstationList = ({ UsersMessages, classes }) => {
  const dispatch = useDispatch();

  let selecteUserChat = useSelector((state) => state.seeChat.chat);
  console.log(selecteUserChat, "selevctft");

  const checkingUserSelectedOrNot = (id) => {
    // TODO document why this arrow function is empty

    if (selecteUserChat?.member?.[0]?._id === id) {
      let styleObject = { background: "#40634c", color: "white", py: 1, pl: 1 };
      return styleObject;
    } else if (
      !selecteUserChat &&
      UsersMessages?.[0]?.member?.[0]?._id === id
    ) {
      let styleObject = { background: "#40634c", color: "white", py: 1, pl: 1 };
      return styleObject;
    } else {
      let styleObject = { background: "", py: 2, pl: 1 };
      return styleObject;
    }
  };

  console.log(UsersMessages, "message");

  let Array = [];

  const uniqueMembers = new Set();

  // Filter out duplicate members by name
  const filteredConversations = UsersMessages.reduce(
    (filtered, conversation) => {
      const memberName = conversation.member[0].name; // Assuming there's always at least one member in each conversation

      // Check if the member name already exists in the Set
      if (!uniqueMembers.has(memberName)) {
        // Add the member name to the Set
        uniqueMembers.add(memberName);

        // Add the conversation to the filtered array
        filtered.push(conversation);
      }

      return filtered;
    },
    []
  );

  // const uniqueMemberIds = new Set();

  console.log(filteredConversations, "sesdjkhfkasgdifhsdifjashdih");
  return (
    <Paper className={classes.users}>
      <List sx={{ pt: 0 }}>
        {filteredConversations?.map((user) => {
          return user?.member?.map((item) => {
            return (
              <ListItem
                key={user.id}
                sx={checkingUserSelectedOrNot(item._id)}
                disablePadding
                onClick={() => dispatch(seeOneToOneChat(user))}
              >
                <div className="list-user-list-item">
                  <div className="icon-and-info">
                    <ListItemIcon>
                      <img
                        src={
                          item.profileImage === null
                            ? placeHolderImage
                            : item.profileImage
                        }
                        alt={item.name}
                        className="list-user-image"
                      />
                    </ListItemIcon>

                    <Box className="user-name-message">
                      <ListItemText id={item._id} primary={`${item.name}`} />
                      <div className="chat-bot-user-email">
                        Job Description - {user?.jobData?.jobDescription}
                      </div>
                    </Box>
                  </div>
                  <div style={{ display: "flex" }}>
                    <p>
                      {moment(
                        user?.lastMsg?.createdAt?.seconds * 1000 +
                          user?.lastMsg?.createdAt?.nanoseconds / 1000000
                      ).fromNow("")}
                    </p>
                  </div>
                </div>
              </ListItem>
            );
          });
        })}
      </List>
    </Paper>
  );
};

export default ConverstationList;
