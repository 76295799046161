import { Troubleshoot } from "@mui/icons-material";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";

import { MdOutlineRefresh } from "react-icons/md";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { AiFillCaretRight, AiFillCaretLeft } from "react-icons/ai";
import { getMonthData, getWeekData, getYearData } from "../Axios/ApisCall";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

let options = {
  chart: {
    height: 350,
    type: "bar",
    background: "white",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50px",
    },
  },

  tooltip: {
    enabled: true,
    x: {
      formatter: function (val) {
        console.log(val, "sbfkksdahkfhsadhf");
        return val;
      },
    },
  },
  dataLabels: {
    enabled: false,
    formatter: function (val) {
      return "$ " + val;
    },
    offsetY: -20,
    style: {
      fontSize: "12px",
      colors: ["#304758"],
    },
  },
  colors: ["#2B5238"],
  xaxis: {
    categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
  yaxix: {
    labels: {
      show: false,
      formatter: function (val) {
        return val + "%";
      },
    },
  },
};

let series = [
  {
    name: "Week Earning",
    data: [30, 40, 35, 50, 49, 60, 70],
  },
];

const EarningCard = () => {
  const date = moment(); // use current date

  const [isRotating, setIsRotating] = useState(false);
  const currentWeekNumber = moment().week();
  const [render, setRender] = useState({
    year: false,
    month: false,
    week: false,
  });

  const [renderMonth, setReenderMonth] = useState(false);
  const [renderYear, setRenderYear] = useState(false);
  const [renderWeek, setrenderWeek] = useState(false);

  const [selectedOption, setSelectedOption] = useState("year");
  const [pickedDate, setPickedDate] = useState({
    year: moment().year(),
    startDay: "",
    endDay: "",
    month: moment().month(),
  });

  const weekNumber = date.week(); // get week number
  const monthNumber = date.month() + 1; // get month number (add 1 to get 1-12 range)
  const year = date.year(); // get year

  const [earning, setEarning] = useState({ options, series });

  const handleWeekNumber = (weekNumber) => {
    // Get the current year
    const year = moment().year();

    // Get the start and end of the specified week
    const startOfWeek = moment().year(year).week(weekNumber).startOf("week");
    const endOfWeek = moment().year(year).week(weekNumber).endOf("week");

    // Set the start and end dates in state

    setPickedDate({
      ...pickedDate,
      startDay: startOfWeek.format("YYYY-MM-DD"),
      endDay: endOfWeek.format("YYYY-MM-DD"),
    });
  };

  useEffect(() => {
    if (selectedOption === "week") {
      const WeekDates = [];
      let currentDate = moment(pickedDate.startDay, "YYYY-MM-DD");
      while (currentDate <= moment(pickedDate.endDay, "YYYY-MM-DD")) {
        WeekDates.push(currentDate.format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      console.log(WeekDates, "value");

      getWeekData(pickedDate).then((res) => {
        const result = WeekDates.map((date) => {
          const matchingData = res.data.find(
            (obj) =>
              moment(obj.dateOfData).format("dd") === moment(date).format("dd")
          );
          console.log(matchingData, "bjfhhakjhkwjhoifhu");

          if (matchingData) {
            return {
              date,
              count: parseFloat(matchingData.count),
            };
          } else {
            return {
              date,
              count: 0,
            };
          }
        });
        console.log(result, "resoulslkn");
        setEarning({
          ...earning,
          options: {
            xaxis: {
              categories:
                WeekDates &&
                WeekDates.map((date) => {
                  const d = new Date(date);
                  const options = { weekday: "short", day: "numeric" };
                  return d.toLocaleDateString("en-US", options);
                }),
              // x: moment(data.datetime).format('DD ddd'),
            },
          },
          series: [
            {
              name: "Earning",
              data: result?.map((item) => item.count),
            },
          ],
        });
      });

      setrenderWeek(false);
    }
  }, [pickedDate.startDay, pickedDate.endDay, renderWeek]);

  useEffect(() => {
    if (selectedOption === "month") {
      getMonthData(pickedDate).then((res) => {
        console.log(res, "month");
        if (res) {
          let arr = Object.entries(res).map(([key, value]) => ({ key, value }));
          console.log(arr, "res data ");

          setEarning({
            ...earning,
            options: {
              xaxis: {
                categories: arr.map((item, index) => `week ${index + 1}`),
              },
            },
            series: [
              {
                name: "Earning",
                data: arr.map((item) => item.value),
              },
            ],
          });
        }
      });
    }
    console.log("month api calll");
    setReenderMonth(false);
  }, [pickedDate.month, renderMonth]);

  useEffect(() => {
    if (selectedOption === "year") {
      console.log("year function work");
      let YearData = {
        year: pickedDate.year,
      };
      getYearData(YearData).then((res) => {
        if (res) {
          setEarning({
            ...earning,
            options: {
              xaxis: {
                categories: ["Q-1", "Q-2", "Q-3", "Q-4"],
              },
            },
            series: [
              {
                name: `${pickedDate.year}`,
                data: [
                  `${res.data?.q1}`,
                  `${res.data?.q2}`,
                  `${res.data?.q3}`,
                  `${res.data?.q4}`,
                ],
              },
            ],
          });
        }
      });
    }
    setRenderYear(false);
  }, [pickedDate.year, renderYear]);

  const onPreviousHandler = () => {
    if (selectedOption === "year") {
      let previousYear = pickedDate.year - 1;
      setPickedDate({ ...pickedDate, year: previousYear });
      setRenderYear(true);
    } else if (selectedOption === "month" && pickedDate.month === 11) {
      let newMonth = pickedDate.month - 1;
      setPickedDate({ ...pickedDate, month: newMonth });
      console.log("condition matched");
    } else if (selectedOption === "month" && pickedDate.month === 0) {
      let NewYear = pickedDate.year - 1;
      setPickedDate({ ...pickedDate, year: NewYear, month: 11 });
      setReenderMonth(true);
      console.log("condition matched");
    } else if (
      selectedOption === "month" &&
      pickedDate.month > 0 &&
      pickedDate.month < 11
    ) {
      let newMonth = pickedDate.month - 1;
      setPickedDate({ ...pickedDate, month: newMonth });
      console.log("condition matched");
    } else if (selectedOption === "week") {
      let NextWeekStartDate = moment(
        pickedDate.startDay,
        "YYYY-MM-DD"
      ).subtract(1, "week");
      let NextWeekEndDate = moment(pickedDate.endDay, "YYYY-MM-DD").subtract(
        1,
        "week"
      );

      setPickedDate({
        ...pickedDate,
        startDay: moment(NextWeekStartDate).format("YYYY-MM-DD"),
        endDay: moment(NextWeekEndDate).format("YYYY-MM-DD"),
      });
    }
  };

  const onNextHandler = () => {
    if (selectedOption === "year") {
      let previousYear = pickedDate.year + 1;
      setRenderYear(true);
      setPickedDate({ ...pickedDate, year: previousYear });
    } else if (selectedOption === "month" && pickedDate.month === 11) {
      let newYearStart = pickedDate.year + 1;
      setReenderMonth(true);

      setPickedDate({ ...pickedDate, year: newYearStart, month: 0 });
    } else if (selectedOption === "month") {
      let newMonth = pickedDate.month + 1;
      setPickedDate({ ...pickedDate, month: newMonth });
    } else if (selectedOption === "week") {
      let NextWeekStartDate = moment(pickedDate.startDay, "YYYY-MM-DD").add(
        1,
        "week"
      );
      let NextWeekEndDate = moment(pickedDate.endDay, "YYYY-MM-DD").add(
        1,
        "week"
      );

      setPickedDate({
        ...pickedDate,
        startDay: moment(NextWeekStartDate).format("YYYY-MM-DD"),
        endDay: moment(NextWeekEndDate).format("YYYY-MM-DD"),
      });
    }
  };

  let ShowDate;
  if (selectedOption === "week") {
    ShowDate =
      moment(pickedDate.startDay).format("MMM") +
      `   ${moment(pickedDate.startDay).format("D")}` +
      `-${moment(pickedDate.endDay).format("D")}` +
      `, ${moment(pickedDate.startDay).format("YYYY")}`;
  } else if (selectedOption === "month") {
    ShowDate =
      moment().month(pickedDate.month).format("MMM") + `, ${pickedDate.year}`;
  } else if (selectedOption === "year") {
    ShowDate = pickedDate.year;
  }

  const onChangeRender = (dataValue) => {
    if (dataValue === "week") {
      setrenderWeek(true);
    } else if (dataValue === "month") {
      setReenderMonth(true);
    } else if (dataValue === "year") {
      setRenderYear(true);
    }
  };

  return (
    <Card sx={{ maxWidth: 100 + "%", px: 2, pt: 0 }}>
      <div
        className="d-flex "
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h5
          style={{
            fontSize: "16px",
            marginLeft: "15px",
            color: "gray",
            // marginBottom: "5px",
          }}
        >
          Earning
        </h5>
        <div className="d-flex date-mode-selection" style={{ display: "flex" }}>
          <div
            className={selectedOption === "year" ? "isSelectedMode" : ""}
            onClick={() => {
              handleWeekNumber(currentWeekNumber);
              setSelectedOption("year");
              onChangeRender("year");
              setPickedDate({ ...pickedDate, year: moment().year() });
            }}
          >
            Year
          </div>
          <div
            className={selectedOption === "month" ? "isSelectedMode" : ""}
            onClick={() => {
              handleWeekNumber(currentWeekNumber);
              setSelectedOption("month");
              onChangeRender("month");
              setPickedDate({ ...pickedDate, month: moment().month() });
            }}
          >
            Month
          </div>
          <div
            className={selectedOption === "week" ? "isSelectedMode" : ""}
            onClick={() => {
              handleWeekNumber(currentWeekNumber);
              setSelectedOption("week");
              onChangeRender("week");
            }}
          >
            Week
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 10px",
          width: "100%",
        }}
      >
        <div>
          <span
            style={{
              fontSize: "18px",
              display: "flex",
              alignItems: "center",
              boxShadow: "0  0 1px 0px rgba(0,0,0,0.98)",
            }}
            onClick={onPreviousHandler}
          >
            <IconButton aria-label="previous" style={{ padding: "0" }}>
              <KeyboardArrowLeftIcon style={{ padding: "0" }} />
            </IconButton>
          </span>
        </div>
        <div>
          <h4 style={{ margin: "0", fontSize: "18px", marginLeft: "8px" }}>
            {ShowDate}
          </h4>
        </div>
        <div>
          <span
            style={{
              fontSize: "18px",
              display: "flex",
              alignItems: "center",
              boxShadow: "0  0 1px 0px rgba(0,0,0,0.98)",
              marginRight: "20px",
            }}
            onClick={onNextHandler}
          >
            <IconButton aria-label="Next" style={{ padding: "0" }}>
              <ChevronRightIcon style={{ padding: "0" }} />
            </IconButton>
          </span>
        </div>

        {/* <Tooltip title="Refresh" placement="left">
            <span
              style={{ fontSize: "22px", color: "gray", marginTop: "10px" }}
              onClick={() => {
                setIsRotating(true);
                // perform your refresh logic here
                setTimeout(() => {
                  setIsRotating(false);
                  if (selectedOption === "week") {
                    handleWeekNumber(currentWeekNumber);
                  } else if (selectedOption === "month") {
                    setPickedDate({
                      ...pickedDate,
                      year: moment().year(),
                      month: moment().month(),
                    });
                  } else {
                    setPickedDate({
                      ...pickedDate,
                      year: moment().year(),
                    });
                  }
                }, 1000);
              }}
            >
              <MdOutlineRefresh
                className={`fa fa-refresh ${isRotating ? "rotate" : ""}`}
              />
            </span>
          </Tooltip> */}
      </div>

      <div>
        <ReactApexChart
          options={earning.options}
          series={earning.series}
          type="bar"
          height={300}
        />
      </div>
    </Card>
  );
};

export default EarningCard;
