import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import SanjayImage from "../../Assets/Images/sizx.png";
import NikhilImage from "../../Assets/Images/four.png";
import LakhanImage from "../../Assets/Images/one.png";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import ConverstationList from "./ConverstationList";
import ConversationChat from "./ConversationChat";
import { collection, doc, getDocs, getFirestore } from "firebase/firestore";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { getDatabase, onValue, ref } from "firebase/database";
import { database } from "../../firebase";
import { db } from "../../firebase";
import { useState } from "react";

const UsersMessages = [];

const useStyles = makeStyles((theme) => ({
  root: {
    height: "80vh",
  },
  users: {
    height: "calc(80vh)",
    overflow: "auto",
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    height: "calc(80vh - 143px)",
    overflow: "auto",
  },
  input: {
    margin: theme.spacing(2),
  },
}));

function FixerConversation() {
  const classes = useStyles();
  const [chatUser, setChatUsers] = React.useState([]);

  const [state, setState] = useState(false);

  const { user_name, id } = useParams();
  const navigate = useNavigate();

  const GobackFixerPage = () => {
    navigate(`${process.env.PUBLIC_URL}/fixers`);
  };

  const getAllChats = async () => {
    const ref = collection(db, "Chats");

    // const questionsRef = collection(ref, "messages");
    const questionsSnapshot = await getDocs(ref);
    const data = questionsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setChatUsers(data);
  };

  // const chatWithUser = chatUser?.filter((item) => {
  //   let { members, memberIds, id } = item;
  //   return item.memberIds?.includes(user?._id);
  // });

  // const filteredData = chatUser.map((obj) => {
  //   obj.member = obj.members.filter((member) => member._id !== user?._id);
  //   return obj.member;
  // });

  const filteredArray = chatUser?.filter((item) => {
    item.member = item.members.filter((member) => member._id !== id);
    return item?.memberIds?.some((obj) => obj === id);
  });

  React.useEffect(() => {
    getAllChats();
  }, []);


  return (
    <div style={{ marginTop: "0px !important", padding: "0px !important" }}>
      <div
        style={{
          display: "flex",
          margin: "0px !important",
          height: "30px",
          marginBottom: "20px",
        }}
      >
        <IconButton color="inherit" onClick={() => GobackFixerPage()}>
          <ArrowBack size="small" sx={{ fontSize: "16px" }} />
        </IconButton>
        <h5 className="header-fixer-message" style={{ margin: "5px" }}>
          {user_name}{" "}
          <span style={{ fontSize: "13px", fontWeight: "500" }}>
            Converstation with user's
          </span>
        </h5>
      </div>
      {filteredArray?.length !== 0 ? (
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ConverstationList
              UsersMessages={filteredArray}
              classes={classes}
            />{" "}
            {/* message users */}
          </Grid>
          <Grid item xs={8}>
            <ConversationChat
              UsersMessages={filteredArray}
              classes={classes}
              LakhanImage={LakhanImage}
            />{" "}
            {/* chat detail user */}
          </Grid>
        </Grid>
      ) : (
        <div style={{ padding: "0px !important" }}>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                mt: 7,
                pt: 5,
                flexDirection: "column",
              }}
            >
              <Typography variant="h5" gutterBottom>
                No users Chat found
              </Typography>
              <Typography variant="body1" color="text.secondary">
                There are no users in the system.
              </Typography>
            </Box>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default FixerConversation;
