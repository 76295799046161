export function CustomCellRenderer({ imageSrc, name, email }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={imageSrc}
        alt={`${name}`}
        style={{
          marginRight: 8,
          width: "30px",
          height: "30px",
          borderRadius: "50%",
        }}
      />
      <div>
        <div>{`${name}`}</div>
        <div style={{ color: "#999", fontSize: 12 }}>{email}</div>
      </div>
    </div>
  );
}
