import { Card, Typography } from "@mui/material";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const AllJobsCountGraph = ({ state }) => {
  return (
    <Card sx={{ minWidth: 240, height: "317px", pt: 1, px: 2 }}>
      <h5
        style={{
          margin: "0px",
          fontSize: "16px",
          color: "gray",
          paddingTop: "10px",
        }}
      >
        All Jobs
      </h5>
      {state?.length === 0 ? (
        <div className="No-data-jobs">
          <Typography variant="body1">No Data.</Typography>
        </div>
      ) : (
        <ReactApexChart
          options={state.options}
          series={state.Series}
          type="donut"
          height={280}
        />
      )}
    </Card>
  );
};

export default AllJobsCountGraph;
