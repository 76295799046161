import { CustomCellRenderer } from "../CustomeTable/CustomCellRenderer";

import ActionMenu from "../CustomeTable/ActionMenu";

export const columns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 320,

    sortable: false,
    renderCell: (params) => {
      const { FullName, email, gender } = params.row;
      return (
        <CustomCellRenderer
          name={FullName}
          imageSrc={
            gender !== "Male"
              ? "https://api-prod-minimal-v4.vercel.app/assets/images/avatars/avatar_20.jpg"
              : "https://api-prod-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg"
          }
          email={email}
        />
      );
    },
  },
  { field: "", headerName: "Address", width: 330, sortable: false },
  {
    field: "mobileNo",
    headerName: "Mobile Number",
    flex: 1,
    sortable: false,
  },
  {
    field: "age",
    headerName: "Age",
    width: 60,
    sortable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "",
    headerName: "Rating",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      const { rating } = params.row;
      {
      }
      return (
        <div>
          {rating === "1"
            ? "⭐"
            : rating === "2"
            ? "⭐⭐"
            : rating === "3"
            ? "⭐⭐⭐"
            : rating === "4"
            ? "⭐⭐⭐⭐"
            : rating === "5"
            ? "⭐⭐⭐⭐⭐"
            : ""}
        </div>
      );
    },
  },
  {
    field: "action",
    headerName: "Action",
    minWidth: 80,
    align: "center",
    headerAlign: "center",
    sortable: false,
    renderCell: (params) => (
      <>
        {params.value}
        <ActionMenu pickUsers={params} />
      </>
    ),
  },
];

export const initialRows = [
  {
    id: 1,

    firstName: "John",
    lastName: "Doe",
    imageSrc:
      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",

    email: "lakhannemane@gmail.com",
    phoneNumber: "+91 8934291623",
    address: "baner balewadi, Pune",
    age: "37",
    rating: "5",
    CreateOn: "21/2/2023",
  },
  {
    id: 2,

    firstName: "Lakhan",
    lastName: "Nemane",
    imageSrc:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    email: "lakhannemane@gmail.com",
    phoneNumber: "+91 8934291623",
    address: "Balewadi Stadium, Pune",
    age: "23",
    rating: "5",
    CreateOn: "21/2/2023",
  },
  {
    id: 3,

    firstName: "Sanjay",
    lastName: "Arora",
    imageSrc:
      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",

    email: "sanjay@gmail.com",
    phoneNumber: "+91 8934291623",
    address: "Kalewadi phata, Pune",
    age: "27",
    rating: "4",
    CreateOn: "21/2/2023",
  },
  {
    id: 4,

    firstName: "Nikhil",
    lastName: "Borge",
    imageSrc:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    email: "nikgil@gmail.com",
    phoneNumber: "+91 8934291623",
    age: "37",
    address: "Kalewadi phata, Pune",
    rating: "4",
    CreateOn: "21/2/2023",
  },
  {
    id: 5,

    firstName: "Gajanan",
    lastName: "Khope",
    imageSrc:
      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",

    email: "Gajanan@gmail.com",
    phoneNumber: "+91 8934291623",
    address: "Dehu Raod , pune",
    age: "37",
    rating: "4",
    CreateOn: "21/2/2023",
  },
  {
    id: 6,
    firstName: "Jane",
    lastName: "lkarlie",
    imageSrc:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    email: "lakhannemane@gmail.com",
    phoneNumber: "+91 8934291623",
    age: "37",
    rating: "4",
    CreateOn: "21/2/2023",
  },
  {
    id: 7,

    firstName: "John",
    lastName: "Luke",
    imageSrc:
      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",

    email: "lakhannemane@gmail.com",
    phoneNumber: "+91 8934291623",
    age: "37",
    rating: "3",
    CreateOn: "21/2/2023",
  },
  {
    id: 8,

    firstName: "Jane",
    lastName: "Doe",
    imageSrc:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    email: "lakhannemane@gmail.com",
    phoneNumber: "+91 8934291623",
    age: "37",
    rating: "3",
    CreateOn: "21/2/2023",
  },
  {
    id: 9,

    firstName: "John",
    lastName: "Doe",
    imageSrc:
      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",

    email: "lakhannemane@gmail.com",
    phoneNumber: "+91 8934291623",
    age: "37",
    rating: "3",
    CreateOn: "21/2/2023",
  },
  {
    id: 10,

    firstName: "Jane",
    lastName: "Doe",
    imageSrc:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    email: "lakhannemane@gmail.com",
    phoneNumber: "+91 8934291623",
    age: "37",
    rating: "2",
    CreateOn: "21/2/2023",
  },
  {
    id: 11,

    firstName: "John",
    lastName: "Doe",
    imageSrc:
      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",

    email: "lakhannemane@gmail.com",
    phoneNumber: "+91 8934291623",
    age: "37",
    rating: "2",
    CreateOn: "21/2/2023",
  },
  {
    id: 12,

    firstName: "Jane",
    lastName: "Doe",
    imageSrc:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    email: "lakhannemane@gmail.com",
    phoneNumber: "+91 8934291623",
    age: "37",
    rating: "1",
    CreateOn: "21/2/2023",
  },
  {
    id: 13,

    firstName: "John",
    lastName: "Doe",
    imageSrc:
      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",

    email: "lakhannemane@gmail.com",
    phoneNumber: "+91 8934291623",
    age: "37",
    rating: "1",
    CreateOn: "21/2/2023",
  },
  {
    id: 14,

    firstName: "Jane",
    lastName: "Doe",
    imageSrc:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    email: "lakhannemane@gmail.com",
    phoneNumber: "+91 8934291623",
    age: "37",
    rating: "1",
    CreateOn: "21/2/2023",
  },
];
