import styled from "@emotion/styled";
import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import LoginsectionIamge from "../../Assets/Images/MicrosoftTeams-image.png";

const MainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "70%",
  padding: "10px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
}));

const ImageSection = () => {
  return (
    <MainDiv>
      <h2>Welcome! Back To Fixeo </h2>
      <div className="SideImage">
        <img src={LoginsectionIamge} alt="hello" />
      </div>
      <div className="imageContent">
        <Typography variant="body2" color="text.secondary">
          We Are So Grateful To Have You Here. Could Not Do Without You.
        </Typography>
      </div>
    </MainDiv>
  );
};

export default ImageSection;
