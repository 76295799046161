import NavigationFile from "../src/Navigation/index";

function App() {
  
  return (
    <div className="App">
      <NavigationFile />
    </div>
  );
}

export default App;
