import { configureStore } from "@reduxjs/toolkit";
import openAppbarReducer from "./appbarSlice";
import seeChatSliceReducer from "./seeChatSlice";

export const store = configureStore({
  reducer: {
    Open: openAppbarReducer,
    seeChat: seeChatSliceReducer,
  },
});
