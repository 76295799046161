import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const BaseUrl = process.env.REACT_APP_SMART_BASE_URL;

export const AuthLogin = createAsyncThunk("login/AuthLogin", async (params) => {
  try {
    const Response = await axios.post(`${BaseUrl}/admin/login`, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if ((Response.status = "200")) {
    }
    if ((Response.status = "200")) {
      return Response.data;
    }
  } catch (error) {
    return error;
  }
});

export const authLogOut = createAsyncThunk(
  "logout/authLogOut",
  async (params) => {
    try {
      const Response = await axios.put(
        `${BaseUrl}/users/logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Autorization: `${localStorage.getItem("user_token")}`,
          },
        }
      );
      if ((Response.status = "200")) {
      }
      if ((Response.status = "200")) {
        return Response.data;
      }
    } catch (error) {}
  }
);

const authSlice = createSlice({
  name: "AuthLogin",
  initialState: {
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : [],
    isloggedIn: false,
    status: "Idle",
  },
  reducers: {
    LogOut: (state, action) => {
      state.user = "";
      localStorage.clear();
      state.isloggedIn = false;
    },
  },
  extraReducers: {
    [AuthLogin.pending]: (state) => {
      state.status = "pending";
    },
    [AuthLogin.fulfilled]: (state, action) => {
      state.status = "Successfull";
      state.user = action.payload;
    },
    [AuthLogin.rejected]: (state) => {
      state.status = "error occured";
      state.isloggedIn = false;
    },
    [authLogOut.pending]: (state) => {
      state.status = "pending";
    },
    [authLogOut.fulfilled]: (state, action) => {
      state.status = "Successfull";
      state.user = "";
      localStorage.clear();
      state.isloggedIn = false;
    },
    [authLogOut.rejected]: (state) => {
      state.status = "error occured";
      state.isloggedIn = false;
    },
  },
});

export default authSlice.reducer;

export const { LogOut } = authSlice.actions;
