import { createSlice } from "@reduxjs/toolkit";

const seeUserChatSlice = createSlice({
  name: "seeChat",
  initialState: {
    user: "",
    chat: "",
    status: "idle",
  },
  reducers: {
    seeUserChat: (state, action) => {
      if (action) {
        state.user = action.payload;
      }
    },
    seeOneToOneChat: (state, action) => {
      if (action) {
        state.chat = action.payload;
      }
    },
  },
});

export const { seeUserChat, seeOneToOneChat } = seeUserChatSlice.actions;
export default seeUserChatSlice.reducer;
