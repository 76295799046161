import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export function CustomeToolbarDashboard() {
  const navigate = useNavigate();
  return (
    <div className="table-header">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ lineHight: "1" }}>
          <h5>Recent Provider</h5>
          <p style={{ margin: "0", padding: "0", color: "rgb(83 81 81)" }}>
            All of them are currently awaiting approval.
          </p>
        </div>
      </div>
    </div>
  );
}
