import { Card } from "@mui/material";
import React from "react";
import "../Assets/CSS/Dashboardcard.css";

const DashboardCard = ({ title, count, CardIcon }) => {
  return (
    <Card sx={{ minWidth: 240, height: "150px" }}>
      <div className="Main-Crad">
        <h3>
          {title}
          <span>{count}</span>
        </h3>
        <div
          className={
            title === "Total users"
              ? "IconCircle first"
              : title === "Fixer"
              ? "IconCircle second"
              : title === "Jobs"
              ? "IconCircle third"
              : ""
          }
        >
          {CardIcon}
        </div>
      </div>
    </Card>
  );
};

export default DashboardCard;
