import { createTheme } from "@mui/material";
import { orange } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary:{
      main:"#2B5238"
    },
    background: {
      default: "#f1f3f5",
    },
  },
  status: {
    danger: orange[500],
  },
});

export default theme;
