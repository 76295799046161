import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { CustomeToolbarDashboard } from "../Pages/Dashboard/CustomeToolbarDashboard";
import {
  getAllDashboardCount,
  getApproveFixer,
  getRejectFixer,
} from "../Axios/ApisCall";
import DashbordAction from "../Pages/Dashboard/DashbordAction";
import { CustomCellRenderer } from "../Pages/Users/CustomeTable/CustomCellRenderer";
import placeHolderImage from "../../src/Assets/Images/placeholder.svg";

export default function DashboardTable({ Awiatingfixers, setRefreshCount }) {
  const [rows, setRows] = useState([]);
  const [renderList, setRenderList] = useState(false);

  useEffect(() => {
    getAllDashboardCount().then((res) => setRows(res.recentProviders));
    setRenderList(false);
  }, [renderList]);

  const handleApprove = (data) => {
    getApproveFixer(data).then((res) => {
      setRenderList(true);
      setRefreshCount(true);
    });
  };

  const handleDisapprove = (data) => {
    getRejectFixer(data).then((res) => {
      setRenderList(true);
    });
  };

  const DashboardColumns = [
    {
      field: "FullName",
      headerName: "Name",
      width: 230,
      sortable: false,
      renderCell: (params) => {
        const { FullName, email, gender, profileImage } = params.row;
        return (
          <CustomCellRenderer
            name={FullName}
            imageSrc={profileImage === null ? placeHolderImage : profileImage}
            email={email}
          />
        );
      },
    },
    {
      field: "Address",
      sortable: false,
      headerName: "Address",
      flex: 1,
      renderCell: (params) => {
        let { Address } = params.row;
        console.log(params.row, "paramas");
        return Address?.city_state || "";
      },
    },
    {
      field: "mobileNo",
      sortable: false,
      headerName: "Mobile Number",
      flex: 1,
    },
    {
      field: "age",
      sortable: false,
      headerName: "Age",
      width: 70,
      align: "center",
      headerAlign: "center",
    },
    { field: "role", sortable: false, headerName: "User Role", flex: 1 },

    {
      field: "action",
      headerName: "",
      width: 30,
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <>
          {params.value}
          <DashbordAction
            pickUsers={params.row}
            onApprove={handleApprove}
            onDelete={handleDisapprove}
          />
        </>
      ),
    },
  ];

  return (
    <div style={{ height: "55vh", width: "100%" }}>
      <DataGrid
        sx={{ background: "white" }}
        rows={rows}
        columns={DashboardColumns}
        disableColumnMenu
        getRowId={(row) => row._id}
        hideFooter={true} // hide the footer
        disableColumnSelector
        pageSize={10}
        rowsPerPageOptions={[10]}
        pagination={false} // disable pagination
        components={{
          Toolbar: () => <CustomeToolbarDashboard />,
        }}
        disableSelectionOnClick
      />
    </div>
  );
}
