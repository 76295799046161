import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import SanjayImage from "../../Assets/Images/sizx.png";
import NikhilImage from "../../Assets/Images/four.png";
import LakhanImage from "../../Assets/Images/one.png";
import ListOfChatUser from "./ListOfChatUser";
import ChatUser from "./ChatUser";
import { Box, Grid, Typography } from "@mui/material";
import { getQueryuserList } from "../../Axios/ApisCall";
import { getConversationById } from "../../Axios/ApisCall";
import { useSelector } from "react-redux";

const UsersMessages = [
  {
    id: 1,
    name: "Laxman Nemane",
    pictureUrl: `${LakhanImage}`,
    lastMessage: "Hi there! How can I help you today?",
    email: "lakhannemane@gmial.com",
    timeStamp: "2023-02-27T10:15:00Z",
    unreadCount: 3,
  },
  {
    id: 2,
    name: "Sanjay Arora",
    pictureUrl: `${SanjayImage}`,
    lastMessage: "I have a question about my account.",
    email: "sanjayarora@gmial.com",
    timeStamp: "2023-02-25T15:20:00Z",
    unreadCount: 0,
  },
  {
    id: 3,
    name: "Nikhil Borge",
    pictureUrl: `${NikhilImage}`,
    lastMessage: "Thanks for your help!",
    timeStamp: "2023-02-23T08:45:00Z",
    email: "nickborge@gmial.com",
    unreadCount: 1,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    height: "80vh",
  },
  users: {
    height: "calc(80vh)",
    overflow: "auto",
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    height: "calc(80vh - 143px)",
    overflow: "auto",
  },
  input: {
    margin: theme.spacing(2),
  },
}));

function Messages() {
  const [selecteUserChat, setSelectedChat] = useState();
  const [loadingMessgages, setLoadingMessages] = useState(false);
  const [UserMessage, setUserMessagess] = useState([]);
  const [firstUserMessage, setFirstUserMessaages] = useState();

  const classes = useStyles();
  useEffect(() => {
    getQueryuserList().then((res) => {
      setUserMessagess(res.data.userList);
    });
    setLoadingMessages(false);
  }, [loadingMessgages]);

  useEffect(() => {
    if (selecteUserChat) {
      getConversationById(selecteUserChat?.userDetails?._id).then((res) =>
        setFirstUserMessaages(res.data)
      );
    }
    setLoadingMessages(false);
  }, [selecteUserChat, loadingMessgages]);

  return (
    <>
      <h5 className="heading-message-page">Query Messages</h5>
      {UserMessage?.length !== 0 ? (
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ListOfChatUser
              UsersMessages={UserMessage}
              firstUserMessage={firstUserMessage}
              classes={classes}
              userChat={selecteUserChat}
              setUser={setSelectedChat}
            />{" "}
          </Grid>
          <Grid item xs={8}>
            <ChatUser
              UsersMessages={UserMessage}
              firstUserMessage={firstUserMessage}
              classes={classes}
              LakhanImage={LakhanImage}
              userChat={selecteUserChat}
              setUser={setSelectedChat}
              loadingMessgages={loadingMessgages}
              setLoadingMessages={setLoadingMessages}
            />{" "}
          </Grid>
        </Grid>
      ) : (
        <div style={{ padding: "0px !important" }}>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                mt: 7,
                pt: 5,
                flexDirection: "column",
              }}
            >
              <Typography variant="h5" gutterBottom>
                No Query Messages found
              </Typography>
              <Typography variant="body1" color="text.secondary">
                There is no user query messages.
              </Typography>
            </Box>
          </Grid>
        </div>
      )}
    </>
  );
}

export default Messages;
