import {
  Box,
  Button,
  List,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getConversationById,
  getQueryuserList,
  getreplayMesage,
} from "../../Axios/ApisCall";
import placeHolderImage from "../../Assets/Images/placeholder.svg";
import moment from "moment";
import EmptySelectUser from "../../Assets/Images/empty.svg";

const ChatUser = ({
  classes,
  LakhanImage,
  UsersMessages,
  firstUserMessage,
  loadingMessgages,
  userChat,
  setLoadingMessages,
}) => {
  const [message, setMessage] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const lastMessageRef = useRef(null);

  const chatUser = useSelector((state) => state.seeChat.user);

  let textMessage;
  if (userChat) {
    textMessage = userChat?.question;
  } else {
    // textMessage = UsersMessages[0]?.question;
  }
  const [messages, setMessages] = useState([]);

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (chatUser) {
      let messageBody = {
        userId: chatUser?.userDetails?._id,
        message: message,
      };
      getreplayMesage(messageBody).then((res) => console.log(res, "messgae"));
    }
    // setMessages([
    //   ...messages,
    //   { id: messages.length + 1, text: message, sender: "Admin" },
    // ]);

    // localStorage.setItem("messaees", JSON.stringify(messages));

    setMessage("");
    setLoadingMessages(true);
  };

  // chat user image setting
  let Image;
  if (userChat) {
    if (userChat?.userDetails?.profileImage === null) {
      Image = placeHolderImage;
    } else {
      Image = userChat?.userDetails?.profileImage;
    }
  }

  let user;
  if (userChat) {
    user = userChat?.userDetails?._id === userChat.senderId ? "user" : "Admin";
  } else {
    user =
      UsersMessages[0]?.userDetails?._id === UsersMessages[0]?.senderId
        ? "user"
        : "Admin";
  }

  useEffect(() => {
    setMessage("");
  }, [userChat]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  console.log(firstUserMessage, "firstuser mesdsD");

  return (
    <>
      {userChat ? (
        <Paper>
          <div className="chat-bot-section">
            <img src={Image} alt={"placholder"} />
            <div className="chat-bot-user-info">
              <div>{userChat?.userDetails?.FullName}</div>
              <div className="chat-bot-user-email ">
                {userChat?.userDetails?.email}
              </div>
            </div>
          </div>
          <Box className={classes.paper}>
            <div className="user-messages">
              <ul className="list-messaegs">
                {firstUserMessage?.map((message, index) => {
                  return (
                    <li primary={message.message}>
                      <div
                        style={
                          userChat &&
                          message.senderId === userChat?.userDetails?._id
                            ? { display: "flex" }
                            : { display: "flex", flexDirection: "row-reverse" }
                        }
                      >
                        <div
                          className="chat-profile"
                          style={
                            message.senderId === userChat?.userDetails?._id
                              ? {}
                              : { marginLeft: "10px" }
                          }
                        >
                          {message.senderId === userChat?.userDetails?._id ? (
                            <img
                              src={
                                userChat?.userDetails?.profileImage === null
                                  ? placeHolderImage
                                  : userChat?.userDetails?.profileImage
                              }
                              alt=""
                            />
                          ) : (
                            <img src={placeHolderImage} alt="" />
                          )}
                        </div>
                        <div className="message-section-text">
                          <p
                            style={
                              message.senderId === userChat?.userDetails?._id
                                ? {
                                    my: 1,
                                    width: "object-fit",
                                    marginRight: "auto",
                                    background: "#9a9aa0",
                                    padding: "10px 30px",
                                    borderRadius: "15px 15px  15px 0",
                                    listStyle: "none",
                                  }
                                : {
                                    my: 1,
                                    width: "object-fit",
                                    marginLeft: "auto",
                                    background: "#b3c1b7",
                                    // width: 70 + "%",
                                    padding: "10px 30px",

                                    borderRadius: "15px 15px 0 15px",
                                    listStyle: "none",
                                  }
                            }
                          >
                            {message.message}
                          </p>
                          <span
                            style={
                              message.senderId === userChat?.userDetails?._id
                                ? {
                                    display: "flex",
                                    fontSize: "12px",
                                    color: "#c2c2c6",
                                  }
                                : {
                                    display: "flex",
                                    fontSize: "12px",
                                    color: "#c2c2c6",
                                    justifyContent: "end",
                                  }
                            }
                          >
                            {moment(message.createdAt).fromNow()}
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}
                <div ref={lastMessageRef}></div>
              </ul>
            </div>
          </Box>
          {/* send messages */}
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", padding: "0 10px 10px 10px" }}
          >
            <TextField
              label="Type a message..."
              variant="outlined"
              fullWidth
              value={message}
              onChange={handleInputChange}
              sx={{ pr: 2 }}
              size="small"
            />
            <Button
              variant="contained"
              size="small"
              color="primary"
              type="submit"
            >
              Send
            </Button>
          </form>
        </Paper>
      ) : (
        <Paper
          sx={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
          }}
        >
          <div>
            <img src={EmptySelectUser} alt="emptychat" />
            <span>Please select a chat to view user messages.</span>
          </div>
        </Paper>
      )}
    </>
  );
};

export default ChatUser;
