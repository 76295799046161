import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import ImageSection from "./ImageSection";
import Login from "./Login";

const LoginPageMainSection = styled("div")(({ theme }) => ({
  background: "#e9ecef",
  height: "100vh",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const CradLoginActivity = styled("div")(({ theme }) => ({
  width: "50%",
  background: "white",
  borderRadius: "10px",
  // height: "90vh",
  margin: "auto",
  boxShadow: "1px 0 4px 1px rgba(0, 0,0,0.1)",
  display: "flex",
  justifyContent: "space-between",
  overflow: "hidden",
}));

const LoginIndex = () => {
  const [value, setValue] = useState(0);
  const [screen, setScreen] = useState(false);

  return (
    <LoginPageMainSection>
      <CradLoginActivity>
        {/* login page/forgot */}
        <div style={{ width: "50%" }}>
          <Login
            value={value}
            setValue={setValue}
            screen={screen}
            setScreen={setScreen}
          />
        </div>
        {/* image section */}
        <div style={{ width: "50%", background: "#2B5238", color: "white" }}>
          <ImageSection />
        </div>
      </CradLoginActivity>
    </LoginPageMainSection>
  );
};

export default LoginIndex;
