import { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { CustomToolbar } from "./CustomeTable/CustomToolbar";
import {
  CircularProgress,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Rating,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  getAllFixersList,
  getApproveFixer,
  getDeleteUser,
  getRejectFixer,
} from "../../Axios/ApisCall";
import { CustomCellRenderer } from "./CustomeTable/CustomCellRenderer";
import ActionMenu from "./CustomeTable/ActionMenu";
import moment from "moment";
import { Box } from "@mui/system";
import CustomNoRowsOverlay from "./NoRow";
import SearchIcon from "@mui/icons-material/Search";
import placeHolderImage from "../../Assets/Images/placeholder.svg";

export default function Fixers() {
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const [allFixers, setAllFixers] = useState([]);
  const [rows, setRows] = useState(allFixers);
  const [renderList, setRenderList] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRows, setFilteredRows] = useState(allFixers);

  const [sendUserMenu, setSendUserMenu] = useState();
  const handleRowClick = (params) => {
    setSendUserMenu(params.row);
    setId(params.row._id);
  };

  const handleSearch = (searchText) => {
    // Filter the rows based on the search criteria

    const filteredRows =
      allFixers &&
      allFixers.filter((row) => {
        return row.FullName?.toLowerCase().includes(searchText.toLowerCase());
        // row.lastName?.toLowerCase().includes(searchText.toLowerCase())
      });
    // Update the rows state with the filtered rows

    setAllFixers(filteredRows);
  };

  useEffect(() => {
    getAllFixersList().then((res) => setAllFixers(res.data));
    setRenderList(false);
  }, [renderList]);

  const handleDelete = (data) => {
    setLoading(true);

    getDeleteUser(data).then((res) => {
      setRenderList(true);
      setLoading(false);
    });
  };

  const handleDisapprove = (data) => {
    setLoading(true);
    getRejectFixer(data).then((res) => {
      if (res) {
        setRenderList(true);
        setLoading(false);
      }
    });
  };

  const handleApprove = (data) => {
    setLoading(true);
    getApproveFixer(data).then((res) => {
      if (res) {
        if (allFixers) {
          setLoading(false);
        }
        setRenderList(true);
      }
    });
  };

  const columns = [
    {
      field: "FullName",
      headerName: "Name",
      width: 230,
      sortable: false,
      renderCell: (params) => {
        const { FullName, gender, email, profileImage } = params.row;

        return (
          <CustomCellRenderer
            name={FullName}
            imageSrc={profileImage === null ? placeHolderImage : profileImage}
            email={email}
          />
        );
      },
    },
    {
      field: "Address",
      headerName: "Address",
      flex: 2,
      sortable: false,
      valueGetter: (params) => {
        return params.row?.Address?.city_state;
      },
    },
    {
      field: "mobileNo",
      sortable: false,
      headerName: "Mobile Number",
      flex: 2,
      valueGetter: (params) => {
        let { countryCode, mobileNo } = params.row;
        let MobileNumbber = `${countryCode} ${mobileNo}`;
        return MobileNumbber;
      },
    },
    {
      field: "age",
      sortable: false,
      headerName: "Age",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let { dob } = params.row;
        let age = moment().diff(dob, "years");
        return age || "";
      },
    },
    {
      field: "category",
      sortable: false,
      headerName: "Category",
      width: 170,
      height: "auto",
      valueGetter: (params) => {
        let { serviceIds } = params.row;
        const uniqueCategories = new Set(
          serviceIds?.map((item) => item.Maincategory)
        );
        let FilterdArrayCaterorie = [...uniqueCategories];

        return FilterdArrayCaterorie?.map((item) => item);
      },
    },

    {
      field: "rating",
      headerName: "Rating",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        const { rating } = params.row;

        return rating ? (
          <Rating
            name="half-rating"
            readOnly
            defaultValue={rating}
            precision={0.5}
          />
        ) : null;
      },
    },
    {
      field: "createdAt",
      sortable: false,
      headerName: " Join Date",
      flex: 1,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY "),
    },
    {
      field: "approved",
      sortable: false,
      headerName: "Verified",
      headerAlign: "center",
      align: "center",
      flex: 2,
      renderCell: (params) => {
        const { approved } = params.row;

        return (
          <div>
            {approved === true ? (
              <div>
                {allFixers && loading && id === params.row._id ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#62CA55",
                      border: "1px solid #62CA55",
                      padding: "2px 8px",
                      borderRadius: "20px",
                    }}
                  >
                    <div style={{}}>
                      <CheckIcon
                        style={{ fontSize: "14px", marginRight: "6px" }}
                      />{" "}
                      Approved
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {allFixers && loading && id === params.row._id ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#FABA3E",
                      border: "1px solid #faba3e",
                      padding: "2px 8px",
                      borderRadius: "20px",
                    }}
                  >
                    <div style={{}}>
                      <HourglassBottomIcon
                        style={{ fontSize: "14px", marginRight: "6px" }}
                      />{" "}
                    </div>
                    Disapproved
                  </div>
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      width: 30,
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <>
          {params.value}
          <ActionMenu
            pickUsers={params.row}
            onDelete={handleDelete}
            onDisapprove={handleDisapprove}
            onApprove={handleApprove}
            user={sendUserMenu}
            loading={loading}
          />
        </>
      ),
    },
  ];

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    const regex = new RegExp(event.target.value, "i");
    const filtered = allFixers.filter((row) => regex.test(row.FullName));

    setFilteredRows(filtered);
  };

  return (
    <div style={{ height: "80vh", width: "100%", position: "relative" }}>
      <div className="searchbar-mui-data-table">
        <SearchIcon color="primary" />

        <input
          type="text"
          name="serchTerm"
          value={searchTerm}
          onChange={(e) => handleSearchChange(e)}
        />
      </div>
      <DataGrid
        sx={{ background: "white" }}
        rows={searchTerm?.length !== 0 ? filteredRows : allFixers}
        columns={columns}
        getRowId={(row) => row._id}
        disableColumnMenu
        pageSize={10}
        rowsPerPageOptions={[10]}
        onRowClick={handleRowClick}
        disableColumnSelector
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
          Toolbar: () => (
            <div className="table-header">
              <h5>Fixers</h5>
              <GridToolbarContainer
                className="tableHeading-Content"
                sx={{ justifyContent: "flex-end" }}
              >
                <GridToolbarExport />
              </GridToolbarContainer>
            </div>
          ),
        }}
        disableSelectionOnClick
      />
    </div>
  );
}
